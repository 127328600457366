import { RECOVER_PASSWORD_START, RECOVER_PASSWORD_SUCCESS, RECOVER_PASSWORD_FAIL } from './ActionTypes';
import clienteAxios from '../config/Axios'
import Swal from 'sweetalert2';


export function RecoverPasswordAction(data, history) {

    return dispatch => {
        dispatch(recoverPasswordStart())
        clienteAxios.patch('/passwordRecovery', data)
            .then(() => {
                dispatch(recoverPasswordSuccess())
                Swal.fire({
                    icon: 'success',
                    title: "¡Su contraseña ha sido recuperada!",
                    text: "Revise su cuenta de correo e inicie sesión con su clave temporal.",
                }).then(function () {
                    history.push('/ingresa');
                });
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo siento',
                    text: 'Intente nuevamente.'
                })
                dispatch(recoverPasswordFail())
            })
    }
}

export const recoverPasswordStart = () => {
    return {
        type: RECOVER_PASSWORD_START
    };
};

export const recoverPasswordSuccess = () => ({
    type: RECOVER_PASSWORD_SUCCESS
})

export const recoverPasswordFail = () => ({
    type: RECOVER_PASSWORD_FAIL
})


