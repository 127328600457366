import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';


//Images
import carKey from './../../resources/images/page/car_key.jpg'
import peopleIcon from './../../resources/images/icons/about_us_icon.png'
import Layout from '../layout';

const AboutUs = () => {
    return (
        <Layout>
            <Container>
                <Row className="justify-content-center my-4">
                    <Col md={12} className="px-0">
                        <Image alt="LLave de vehiculo" fluid src={carKey} />
                    </Col>
                </Row>
                <Row className="py-md-3 p-0 d-flex align-items-center justify-content-center">
                    <Col>
                        <Image alt="Nosotros" width="30%" fluid className="mx-auto d-md-none d-lg-none d-sm-block d-block" src={peopleIcon} />
                    </Col>
                </Row>
                <Row className="py-md-5 py-4 d-flex align-items-center">
                    <Col md={2}>
                        <Image alt="Personas" fluid className="d-none d-sm-none d-md-block d-lg-block" src={peopleIcon} />
                    </Col>
                    <Col md={10} className="px-md-1 px-5">
                        <h1 className="text_red font-weight-bold mb-4">¿Quiénes somos?</h1>
                        <h3 className="text-md-left text-center">tefinancio.com.ar es una plataforma digital que pone a disposición del particular que quiere vender o comprar un vehículo usado, las mejores opciones de financiación. Es una solución de PLATAFORMAS AUTOMOTOR S.A.S., Ariel Ruiz y vinculada a RUIZ AUTOMOTORES, una empresa familiar con más de 50 años de trayectoria en la comercialización de vehículos.
                        </h3>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default AboutUs
