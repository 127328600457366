import { ADD_DATABASE_START, ADD_DATABASE_SUCCESS, ADD_DATABASE_FAIL, DELETE_STAR, DELETE_SUCCESS, DELETE_FAIL } from './ActionTypes';
import clienteAxios from '../config/Axios'
import Swal from 'sweetalert2';


export function AddDatabaseAction(token, platform) {

    const config = {
        headers: { 'Authorization': "Bearer " + token }
    };

    return dispatch => {
        dispatch(AddDatabase())
        clienteAxios.get(platform ? `/get-db-data?platform=${platform}` : '/get-db-data', config)
            .then(() => {
                dispatch(AddDatabaseSuccess())
                Swal.fire({
                    icon: 'success',
                    title: "¡Excelente!",
                    text: "La base de datos se ha cargado correctamente.",
                })
            })
            .catch(error => {
                dispatch(AddDatabaseFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'No autorizado.'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error en servidor.'
                    })
                }
            })
    }
}
export function DeleteDatabaseAction(token, platform) {

    const config = {
        headers: { 'Authorization': "Bearer " + token }
    };

    return dispatch => {
        dispatch(DeleteDatabase())
        clienteAxios.delete(platform ? `/remove-db-data?platform=${platform}` : '/remove-db-data', config)
            .then(() => {
                dispatch(DeleteDatabaseSuccess())
                Swal.fire({
                    icon: 'success',
                    title: "¡Excelente!",
                    text: "La base de datos de ha borrado correctamente.",
                })
            })
            .catch(error => {
                dispatch(DeleteDatabaseFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'No autorizado.'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error en servidor.'
                    })
                }
            })
    }
}


export const AddDatabase = () => {
    return {
        type: ADD_DATABASE_START
    };
};

export const AddDatabaseSuccess = () => ({
    type: ADD_DATABASE_SUCCESS
})

export const AddDatabaseFail = () => ({
    type: ADD_DATABASE_FAIL
})

//----------------------------------
export const DeleteDatabase = () => {
    return {
        type: DELETE_STAR
    };
};

export const DeleteDatabaseSuccess = () => ({
    type: DELETE_SUCCESS
})

export const DeleteDatabaseFail = () => ({
    type: DELETE_FAIL
})



