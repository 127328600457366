import React from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { Global, css } from '@emotion/react';

const Layout = ({ children, Opp0km, Rmotor, notHeader, notFooter }) => {
    return (
        <>
            <Global
                styles={css`
                    /* SCROLL BAR */
                    ::-webkit-scrollbar {
                        width: 10px;
                        background-color: none;
                    }

                    ::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background: ${Opp0km ? "#132651" : Rmotor ? "#FFC72C" : "#9F111B"} !important;
                    }
                `}
            />
            {notHeader ? null : <Header Opp0km={Opp0km} Rmotor={Rmotor} />}
            <main>
                {children}
            </main>
            {notFooter ? null : <Footer Opp0km={Opp0km} Rmotor={Rmotor} />}

        </>
    )
}

export default Layout