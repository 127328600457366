import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

//Images
import OfreceIcon from './../../resources/images/icons/vehicle_icon.png'
import ContactaremosIcon from './../../resources/images/icons/contact_icon.png'
import AsistiremosIcon from './../../resources/images/icons/contract_icon.png'
import ChequeamosIcon from './../../resources/images/icons/calendar_icon.png'
import PagaremosIcon from './../../resources/images/icons/bill_icon.png'

const GeneralStepPurchase = () => {
    return (
        <>
            <Container className="py-5">
                <Row>
                    <Col xs={12}>
                        <h1 className="text_red font-weight-bold text-center text-md-left mb-3">Quiero comprar un usado</h1>
                        <h3>Elija su próximo vehículo, negocie el precio de contado y compre en estos 5 pasos</h3>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="bg_grey">
                <Container>
                    <Row className="d-flex justify-content-center align-items-center py-5">
                        <Col xs={6} md={3} lg={2} className="text-center text-md-left">
                            <Image src={OfreceIcon} className="img-fluid" width="70%" alt="Nosotros icono" />
                        </Col>
                        <Col xs={11} md={9} lg={10} className="pt-4 pt-md-0">
                            <h2 className="text_red text-center text-md-left pb-3 font-weight-bold">Ofrecé </h2>
                            <p className=" text-md-justify">
                                tu vehículo de forma financiada. Puedes agregar el enlace tefinancio.com.ar
                                a tu publicación para facilitarle las cosas al comprador
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid >
                <Container>
                    <Row className="d-flex justify-content-center align-items-center py-5">
                        <Col xs={6} md={3} lg={2} className="text-center text-md-left">
                            <Image src={ContactaremosIcon} fluid width="70%" alt="Nosotros icono" />
                        </Col>
                        <Col xs={11} md={9} lg={10} className="pt-4 pt-md-0">
                            <h2 className="text_red text-center text-md-left pb-3 font-weight-bold">Contactaremos</h2>
                            <p className=" text-md-justify">
                                a tu comprador y tomador del crédito, ni bien recibamos la información de la
                                simulación elegida, para completar toda la información necesaria, y así avanzar en el proceso y conseguir la aprobación del crédito.

                    </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="bg_grey">
                <Container>
                    <Row className="d-flex justify-content-center align-items-center py-5">
                        <Col xs={6} md={3} lg={2} className="text-center text-md-left">
                            <Image src={AsistiremosIcon} fluid width="70%" alt="Nosotros icono" />
                        </Col>
                        <Col xs={11} md={9} lg={10} className="pt-4 pt-md-0">
                            <h2 className="text_red text-center text-md-left pb-3 font-weight-bold">Asistiremos</h2>
                            <p className=" text-md-justify">
                                a usted para completar la documentación habitual del vehículo que se precisa, inclusive podemos realizar gran parte de esos trámites nosotros
                    </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid >
                <Container>
                    <Row className="d-flex justify-content-center align-items-center py-5">
                        <Col xs={6} md={3} lg={2} className="text-center text-md-left">
                            <Image src={ChequeamosIcon} fluid width="70%" alt="Nosotros icono" />
                        </Col>
                        <Col xs={11} md={9} lg={10} className="pt-4 pt-md-0">
                            <h2 className="text_red text-center text-md-left pb-3 font-weight-bold">Chequeamos</h2>
                            <p className=" text-md-justify">
                                la documentación y realizaremos el control del vehículo, previo coordinar la
                                visita y encuentro con usted. Con esto determinamos si está todo 0k para
                                realizar la transacción con su comprador, y coordinamos día y horario con
                                usted y el comprador del vehículo para concluir su negocio.

                    </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="bg_grey">
                <Container>
                    <Row className="d-flex justify-content-center align-items-center py-5">
                        <Col xs={6} md={3} lg={2} className="text-center text-md-left">
                            <Image src={PagaremosIcon} fluid width="70%" alt="Nosotros icono" />
                        </Col>
                        <Col xs={11} md={9} lg={10} className="pt-4 pt-md-0">
                            <h2 className="text_red text-center text-md-left pb-3 font-weight-bold">Pagaremos</h2>
                            <p className=" text-md-justify">
                                a usted en efectivo o con transferencia bancaria y nos ocupamos de realizar
                                la transferencia registral del vehículo. En el mismo momento de recibido el pago entrega la unidad a su comprador y firman la documentación pertinente.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container>
                <Row className="my-5">
                    <Col>
                        <h3 className="mb-3 text_red font-weight-bold">Observaciones:</h3>
                        <h3 className="mb-3">
                            <span className="text_red font-weight-bold">Seguro:</span> cobra en el mismo momento que entrega el vehículo y la documentación.
                </h3>
                        <h3>
                            <span className="text_red font-weight-bold">Tranquilidad con transferencia:</span> a usted le preocupa que no circule a su nombre el vehículo y a
                    nosotros disponer de la prenda para presentar al acreedor y recuperar el efectivo que le
                    adelantamos. No cabe duda que estaremos detrás de los trámites de transferencia para recuperar
                    nuestra inversión lo antes posible!
            </h3>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default GeneralStepPurchase;