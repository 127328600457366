import { SIMULATOR_START, SIMULATOR_SUCCESS, SIMULATOR_FAIL, CLEAR_SIMULATOR } from '../actions/ActionTypes';

const initialState = {
    credits: [],
    vehicleData: {},
    capitalLimit: null,
    error: null,
    loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SIMULATOR_START:
            return {
                ...state,
                error: null,
                loading: true,
                credits: [],
                vehicleData: {},
                capitalLimit: null
            }
        case SIMULATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                credits: action.payload.credits.data,
                vehicleData: action.payload.vehicleData,
                capitalLimit: action.payload.credits.capitalLimit
            }
        case SIMULATOR_FAIL:
            return {
                ...state,
                loading: false,
                error: true
            }
        case CLEAR_SIMULATOR:
            return {
                ...state,
                credits: [],
                vehicleData: {},
                capitalLimit: null
            }
        default:
            return state;
    }
}