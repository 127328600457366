import { SET_USER_CREDIT_START, SET_USER_CREDIT_SUCCESS, CLEAR_USER_CREDIT } from '../actions/ActionTypes'

const initialState = {
    creditSelected: {},
    vehicleData: {},
    loading: false
}


export default function (state = initialState, action) {
    switch (action.type) {
        case SET_USER_CREDIT_START:
            return {
                ...state,
                loading: true,
                creditSelected: {},
                vehicleData: {}
            }
        case SET_USER_CREDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                creditSelected: action.payload.creditSelected,
                vehicleData: action.payload.vehicleData
            }
        case CLEAR_USER_CREDIT:
            return {
                ...state,
                creditSelected: {},
                vehicleData: {}
            }
        default:
            return state;
    }
}