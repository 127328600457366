import {
    AUTH_START, AUTH_SUCCESS, AUTH_FAIL, LOGOUT, DELETE_USER_START, DELETE_USER_SUCCESS, DELETE_USER_FAIL
} from './ActionTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../config/Axios'

export function AuthAction(user, history, modal) {
    return (dispatch) => {
        dispatch(authStart())
        clienteAxios.post('/users/login', user)
            .then((userData) => {
                dispatch(authSuccess(userData))
                if (!modal) {
                    history.push('/');
                } else {
                    modal();
                    history.push('/solicita');
                }
            })
            .catch(error => {
                dispatch(authFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                }
                else if (error.response.status === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'El email o la contraseña que ingresaste no coinciden, intenta de nuevo o recupera tu contraseña.'
                        // text: 'Error de conexión.'
                    })
                }
                else if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de autenticación.'
                        // text: 'Error de conexión.'
                    })
                }
            })
    }
}

export function DeleteUserAction(token) {
    return (dispatch) => {
        dispatch(deleteUserStart())
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.delete('/users/me', config)
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: "¡Esperamos que vuelvas pronto!",
                    text: "El usuario ha sido eliminado correctamente",
                }).then(() => {
                    dispatch(deleteUserSuccess())
                })
            }).catch(error => {
                dispatch(deleteUserFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de autenticación.'
                    })
                }
            })
    }
}

export function logoutAction(token) {
    return dispatch => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        const data = {

        }
        dispatch(logoutStart())
        clienteAxios.post('/users/logout', data, config)
            .then(() => {
            }).catch(error => {
                if (!error.response) {
                }
            })
    }
}

export const logoutStart = () => ({
    type: LOGOUT
})


export const authStart = () => {
    return {
        type: AUTH_START
    };
};

export const authSuccess = (user) => ({
    type: AUTH_SUCCESS,
    payload: user
})

export const authFail = () => ({
    type: AUTH_FAIL,
})

export const deleteUserStart = () => {
    return {
        type: DELETE_USER_START
    };
};

export const deleteUserSuccess = () => ({
    type: DELETE_USER_SUCCESS
})

export const deleteUserFail = () => ({
    type: DELETE_USER_FAIL,
})
