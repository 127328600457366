import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import LoginStructure from './LoginStructure';
import SignUpStructure from './SignUpStructure';

const LoginAndSignUpModal = (props) => {
  const [signUp, setSignUp] = useState(false);

  const handleClickSignUp = () => {
    setSignUp(!signUp);
  }

  return (
    <Modal
      {...props}
      size="lg"
      centered
    >
      <Modal.Body>
        {!signUp ? <LoginStructure history={props.history} modal={props.onHide} handleClickSignUp={handleClickSignUp} /> : <SignUpStructure history={props.history} modal={props.onHide} handleClickSignUp={handleClickSignUp} />}
      </Modal.Body>
    </Modal>
  );

}

export default LoginAndSignUpModal;