import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '../layout/index';
import SignUpStructure from '../structures/SignUpStructure';

const SignUp = ({ history }) => {
    //FUNCTIONS
    const token = useSelector((state) => state.auth.token);
    if (token) {
        history.push('/');
    }

    return (
        <Layout>
            <SignUpStructure history={history} />
        </Layout >
    )
}

export default SignUp
