import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import Layout from '../layout/index';
//Actions
import { AuthAction } from './../../actions/AuthAction';
import { formValidationAction, validationSuccess, validationFail } from '../../actions/ValidationAction';
import { FacebookAuthAction } from '../../actions/FacebookAuthAction';
import { GoogleAuthAction } from '../../actions/GoogleAuthAction';

const LoginStructure = ({ history, modal, handleClickSignUp }) => {
  // FORM CONST
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  //DISPATCH
  const dispatch = useDispatch();

  //ACTIONS
  const facebookAuth = (data, history, modal) => dispatch(FacebookAuthAction(data, history, modal))
  const googleAuth = (data, history, modal) => dispatch(GoogleAuthAction(data, history, modal))
  const auth = (user, history, modal) => dispatch(AuthAction(user, history, modal));
  const formValidation = () => dispatch(formValidationAction());
  const successValidation = () => dispatch(validationSuccess());
  const failValidation = () => dispatch(validationFail());

  const obtainUserData = e => {
    if (e.target.name === "email") {
      setEmail(e.target.value)
    }
    if (e.target.name === "password") {
      setPassword(e.target.value)
    }
  }

  const loginHandler = (e) => {
    e.preventDefault();
    formValidation()
    if (email.trim() === '' || password.trim() === '') {
      failValidation();
      Swal.fire({
        icon: 'error',
        title: 'Lo siento',
        text: 'Campos incompletos'
      })
      return;
    }
    successValidation()
    const user = {
      email: email,
      password: password
    }
    auth(user, history, modal)
  }

  const responseGoogle = (response) => {
    if (response.error) return;
    const data = {
      tokenId: response.tokenId
    }
    googleAuth(data, history, modal)
  }

  const responseFacebook = (response) => {
    if (response.status === "unknown") return;
    const data = {
      accessToken: response.accessToken,
      userID: response.userID
    }
    facebookAuth(data, history, modal)
  }

  return (
    <Container>
      <Row className="justify-content-center py-5">
        <Col xs={12} className="text-center">
          <h1 className="text_red font-weight-bold">Iniciar Sesión</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={11} sm={9} md={7} lg={10} xl={5}>
          <FacebookLogin
            appId="958667088342733"
            autoLoad={false}
            fields="name,email,picture"
            // onClick={componentClicked}
            callback={responseFacebook}
            cssClass="my-facebook-button-class btn col-12 mb-3 py-3 facebook"
            icon="fab fa-facebook-f ml-2 p-0"
            textButton="Ingreso con Facebook"
          />
          <GoogleLogin
            clientId="647987897071-32e9nsmralkn98bos2rvtu7j0sbpgic7.apps.googleusercontent.com"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            className="btn col-12 google py-2 px-2"
            buttonText="Ingreso con Google"
          />
        </Col>
      </Row>
      <Row className="justify-content-center py-3">
        <Col xs={2}>
          <hr />
        </Col>
        <span className="text-secondary mt-1">O</span>
        <Col xs={2} >
          <hr />
        </Col>
      </Row>
      <Row className="justify-content-center pb-3">
        <Col xs={11} sm={9} md={10} lg={8} xl={5}>
          <Form>
            <Form.Group controlId="email">
              <Form.Control size="lg" type="email" placeholder="Correo electrónico" onChange={(e) => obtainUserData(e)} name="email" />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Control size="lg" type="password" placeholder="Contraseña" onChange={(e) => obtainUserData(e)} name="password" />
            </Form.Group>
            <Form.Group>
              <Button variant="primary" size="lg" type="submit" className="col-12" onClick={(e) => loginHandler(e)}>
                INICIAR SESIÓN
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-center pb-5">
        <Col xs={11} sm={9} md={7} lg={6} xl={5} className="text-center">
          <p className="text_grey pb-2"><i>¿No tienes cuenta? {modal ? <span className='text_red' role='button' onClick={handleClickSignUp}><u>Regístrate</u></span> : <Link to={'/registrate'} className="text_red"><u>Regístrate</u></Link>}</i></p>
          {!modal && <p className="text_grey"><i>¿Olvidaste tu contraseña? <Link to={'/recuperar-contrasena'} className="text_red"><u>Recuperar</u></Link></i></p>}
        </Col>
      </Row>
    </Container >
  )
}

export default LoginStructure
