import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top"
// Redux
import { Provider } from 'react-redux';
import { store } from "./store";
// components
import Header from './components/header/Header'
import Footer from './components/footer/Footer'

import Home from './components/pages/Home'
import Steps from './components/pages/Steps'
import Login from './components/pages/Login';
import AboutUs from './components/pages/AboutUs';
import SignUp from './components/pages/SignUp';
import RecoverPassword from './components/pages/RecoverPassword';
import Profile from './components/pages/Profile';
import ModifyProfile from './components/pages/ModifyProfile';
import Request from './components/pages/Request';
import SimulateOportunidades0KM from './components/pages/SimulateOportunidades0KM';
import SimuleteRuizMotor from './components/pages/SimuleteRuizMotor';
import NotFound from './components/NotFound'

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Provider store={store}>
          {/* <Header /> */}
          <main>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/pasos" component={Steps} />
              <Route exact path="/nosotros" component={AboutUs} />

              <Route exact path="/ingresa" component={Login} />
              <Route exact path="/registrate" component={SignUp} />
              <Route exact path="/recuperar-contrasena" component={RecoverPassword} />

              <Route exact path="/perfil" component={Profile} />
              <Route exact path="/modificar-mi-perfil" component={ModifyProfile} />

              <Route exact path="/solicita" component={Request} />

              <Route exact path="/simula-oportunidades0km" component={SimulateOportunidades0KM} />
              <Route exact path="/simula-ruizautomotores" component={SimuleteRuizMotor} />

              <Route exact path="/404" component={NotFound} />
              <Redirect to="/404" />
            </Switch>
          </main>
          {/* <Footer /> */}
        </Provider>
      </ScrollToTop>
    </Router>
  );
}

export default App;
