import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import clienteAxios from './../../config/Axios';
import Layout from '../layout/index'
const Profile = ({ history }) => {
    //FUNCTIONS
    const token = useSelector((state) => state.auth.token);

    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")
    const [lastname, setLastname] = useState("")
    const [firsname, setFirsname] = useState("")
    const [phone, setPhone] = useState("")

    //SESION VALIDATION
    if (token === null) {
        history.push('/ingresa');
    }

    useEffect(() => {
        getDataUser()
    }, [])


    const getDataUser = () => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        clienteAxios.get('/users/me', config)
            .then((res) => {
                let item = res.data
                setEmail(item.email)
                setFirsname(item.firstName)
                setLastname(item.lastName)
                setPhone(item.phone)
                setUsername(item.username)
            })
            .catch(error => {
            })
    }

    return (
        <Layout>
            <Container className="py-5">
                <Row>
                    <Col xs={12}>
                        <h1 className="text_red font-weight-bold text-center text-md-left">Mi Perfil</h1>
                    </Col>
                </Row>
            </Container>
            <Container className="pb-5">
                <Row>
                    <Col xs={12} md={8} className="text-center text-md-left">
                        <p><strong className="text_red">Cliente: </strong>{firsname + " " + lastname}</p>
                        <p><strong className="text_red">Email: </strong>{email}</p>
                        <p><strong className="text_red">Usuario: </strong>{username}</p>
                        <p><strong className="text_red">Teléfono: </strong>{phone}</p>
                    </Col>
                    <Col xs={12} md={4} className="text-center text-md-right pt-3 pt-md-0">
                        <Link to={'/modificar-mi-perfil'} className="btn btn-primary text-uppercase px-5">modificar datos</Link>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
};

export default Profile;
