import { SIMULATOR_START, SIMULATOR_SUCCESS, SIMULATOR_FAIL, CLEAR_SIMULATOR } from './ActionTypes';
import clienteAxios from '../config/Axios';
import Swal from 'sweetalert2';

export function SimulatorAction(data, queryPlatform) {
    return dispatch => {
        dispatch(simulatorStart());
        clienteAxios.post(queryPlatform ? `/simulator?${queryPlatform}` : '/simulator', data).then(res => {
            dispatch(simulatorSuccess(res.data, data, res.capitalLimit));
        }).catch(err => {
            dispatch(simulatorFail());
            Swal.fire({
                icon: 'error',
                title: 'Lo siento.',
                text: 'Error de conexión'
            })
        })
    }
}

export const simulatorStart = () => ({
    type: SIMULATOR_START
});

export const simulatorSuccess = (credits, vehicleData, capitalLimit) => ({
    type: SIMULATOR_SUCCESS,
    payload: {
        credits, vehicleData, capitalLimit
    }
})

export const simulatorFail = () => ({
    type: SIMULATOR_FAIL
})

export const clearSimulator = () => ({
    type: CLEAR_SIMULATOR
})
