import { MODIFY_USER_START, MODIFY_USER_SUCCESS, MODIFY_USER_FAIL } from './ActionTypes';
import clienteAxios from '../config/Axios'
import Swal from 'sweetalert2';


export function ModifyUserAction(data, token, history) {

    const config = {
        headers: { 'Authorization': "Bearer " + token }
    };

    return dispatch => {
        dispatch(modifyUserStart())
        clienteAxios.patch('/users/me', data, config)
            .then(() => {
                dispatch(modifyUserSuccess())
                Swal.fire({
                    icon: 'success',
                    title: "¡Excelente!",
                    text: "Sus datos han sido editados correctamente, serán reflejados en su próximo inicio de sesión.",
                }).then(function () {
                    history.push('/perfil');
                });
            })
            .catch(error => {
                dispatch(modifyUserFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'No autorizado.'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error en servidor.'
                    })
                }
            })
    }
}

export const modifyUserStart = () => {
    return {
        type: MODIFY_USER_START
    };
};

export const modifyUserSuccess = () => ({
    type: MODIFY_USER_SUCCESS
})

export const modifyUserFail = () => ({
    type: MODIFY_USER_FAIL
})


