import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../layout/index';
// structures
import FilterSelect from './../structures/oportunidades0km/FilterSelect';
import QuotaTable from './../structures/oportunidades0km/QuotaTable';
// //Actions
import { clearSimulator } from '../../actions/SimulatorAction'
import { clearUserCredit } from '../../actions/SetUserCreditAction'
import {
    useLocation
} from "react-router-dom";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}


const Home = ({ history }) => {
    let query = useQuery();

    const dispatch = useDispatch();

    const simulatorAction = () => dispatch(clearSimulator());
    const setUserCreditAction = () => dispatch(clearUserCredit())

    useEffect(() => {
        simulatorAction();
        setUserCreditAction()
    }, [])



    return (
        <Layout Opp0km notHeader notFooter>
            <FilterSelect history={history} />
            <QuotaTable Opp0km history={history} />
            <div className='Opp0km_GoBack'>
                <a className="btn" href={`${query.get("retorno") ? query.get("retorno") : "https://www.oportunidades0km.com/"}`}>
                    <svg width="20" fill='#fff' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z" />
                    </svg>
                </a>
            </div>
        </Layout>
    );
}

export default Home;