import { combineReducers } from 'redux';
import validationReducer from './Validation';
import signUp from './SignUp';
import authReducer from './Auth';
// import favoritesReducer from './Favorites';
// import welcomeMessageReducer from './WelcomeMessage';
// import handleIndividualCarReducer from './HandleIndividualCar';
// import addConcesionariaReducer from './AddConcesionaria';
// import modifyConcesionariaReducer from './ModifyConcesionaria';
import modifyProfileReducer from './ModifyProfile';
// import modifyUserFromAdminReducer from './ModifyUserFromAdmin';
import recoverPasswordReducer from './RecoverPassword';
// import popUpReducer from './PopUp';
import simulatorReducer from './Simulator';
import setUserCreditReducer from './SetUserCredit';

export default combineReducers({
    validation: validationReducer,
    signUp: signUp,
    auth: authReducer,
    // favorites: favoritesReducer,
    // welcomeMessage: welcomeMessageReducer,
    // handleIndividualCar: handleIndividualCarReducer,
    // addConcesionaria: addConcesionariaReducer,
    // modifyConcesionaria: modifyConcesionariaReducer,
    modifyUser: modifyProfileReducer,
    // modifyUserFromAdmin: modifyUserFromAdminReducer,
    recoverPassword: recoverPasswordReducer,
    // popUp: popUpReducer
    simulator: simulatorReducer,
    setUserCredit: setUserCreditReducer
});
