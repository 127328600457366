import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

// axios
import clienteAxios from './../../config/Axios';
//Actions
import { SignUpAction } from './../../actions/SignUpAction';
import { formValidationAction, validationSuccess, validationFail } from './../../actions/ValidationAction';
import { FacebookAuthAction } from '../../actions/FacebookAuthAction';
import { GoogleAuthAction } from '../../actions/GoogleAuthAction';

const SignUpStructure = ({ history, modal, handleClickSignUp }) => {
  //VALIDATION FORM CONST
  const [firstName, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [phone, setPhone] = useState('0')

  //DISPATCH
  const dispatch = useDispatch();

  const formValidation = () => dispatch(formValidationAction());
  const successValidation = () => dispatch(validationSuccess());
  const failValidation = () => dispatch(validationFail());

  const facebookAuth = (data, history, modal) => dispatch(FacebookAuthAction(data, history, modal))
  const googleAuth = (data, history, modal) => dispatch(GoogleAuthAction(data, history, modal))
  const signUp = (data, history, modal) => dispatch(SignUpAction(data, history, modal));

  const obtainUserData = e => {
    if (e.target.name === "name") {
      setName(e.target.value)
    } else if (e.target.name === "LastName") {
      setLastName(e.target.value)
    } else if (e.target.name === "username") {
      setUsername(e.target.value)
    } else if (e.target.name === "email") {
      setEmail(e.target.value)
    } else if (e.target.name === "password") {
      setPassword(e.target.value)
    } else if (e.target.name === "phone") {
      setPhone(e.target.value)
    } else if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value)
    }
  }

  const SignUpHandler = e => {
    formValidation()
    e.preventDefault();
    if (firstName.trim() === '' || lastName.trim() === '' || username.trim() === '' || email.trim() === '' || password.trim() === '' || confirmPassword.trim() === '' || phone.trim() === "") {
      failValidation()

      Swal.fire({
        icon: 'error',
        title: 'Lo siento',
        text: 'Campos incompletos'
      })
      return;
    } else if (password.length < 8) {
      failValidation()

      Swal.fire({
        icon: 'error',
        title: 'Lo siento',
        text: 'La contraseña debe contener al menos ocho (8) dígitos'
      })
      return;
    } else if (password.trim() !== confirmPassword.trim()) {
      failValidation()

      Swal.fire({
        icon: 'error',
        title: 'Lo siento',
        text: 'La contraseña debe coincidir'
      })
      return;
    }

    successValidation()
    const data = {
      firstName,
      lastName,
      username,
      email,
      password,
      phone
    }

    signUp(data, history, modal)

  }

  // const responseGoogle = (response) => {
  const responseGoogle = (response) => {
    const data = {
      tokenId: response.tokenId
    }
    googleAuth(data, history, modal)
  }

  const responseFacebook = (response) => {
    const data = {
      accessToken: response.accessToken,
      userID: response.userID
    }
    facebookAuth(data, history, modal)
  }

  // user and email confimation exist
  const CheckEmail = (email) => {
    let data = {
      email: email
    }
    clienteAxios.post('/isEmailAvailable', data)
      .then((data) => {
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Lo siento',
          text: 'El email ingresado ya esta en uso.'
        })
      })
  }
  const CheckUsername = (username) => {
    let data = {
      username: username
    }
    clienteAxios.post('/isUsernameAvailable', data)
      .then((data) => {
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Lo siento',
          text: 'El Usuario ingresado ya esta en uso.'
        })
      })
  }
  return (
    <Container>
      <Row className="justify-content-center py-5">
        <Col xs={12} className="text-center">
          <h1 className="text_red font-weight-bold">Regístrate</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={11} sm={9} md={7} lg={10} xl={5}>
          <FacebookLogin
            appId="958667088342733"
            autoLoad={false}
            fields="name,email,picture"
            // onClick={componentClicked}
            callback={responseFacebook}
            cssClass="my-facebook-button-class btn col-12 mb-3 py-3 facebook"
            icon="fab fa-facebook-f ml-2 p-0"
            textButton="Ingreso con Facebook"
          />
          <GoogleLogin
            clientId="647987897071-32e9nsmralkn98bos2rvtu7j0sbpgic7.apps.googleusercontent.com"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            className="btn col-12 google py-2 px-2"
            buttonText="Ingreso con Google"
          />
        </Col>
      </Row>
      <Row className="justify-content-center py-3">
        <Col xs={2}>
          <hr />
        </Col>
        <span className="text-secondary mt-1">O</span>
        <Col xs={2} >
          <hr />
        </Col>
      </Row>
      <Row className="justify-content-center pb-3">
        <Col xs={11} sm={9} md={10} lg={8} xl={5}>
          <Form>
            <Form.Group controlId="email">
              <Form.Control size="lg" type="email" placeholder="Correo electrónico" onChange={(e) => obtainUserData(e)} name="email" onBlur={() => CheckEmail(email)} />
            </Form.Group>
            <Form.Group controlId="name">
              <Form.Control size="lg" type="text" placeholder="Nombre" onChange={(e) => obtainUserData(e)} name="name" />
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Control size="lg" type="text" placeholder="Apellido" onChange={(e) => obtainUserData(e)} name="LastName" />
            </Form.Group>
            <Form.Group controlId="phoneNumber">
              <Form.Control size="lg" type="phone" placeholder="Teléfono" onChange={(e) => obtainUserData(e)} name="phone" />
            </Form.Group>
            <Form.Group controlId="username">
              <Form.Control size="lg" type="text" placeholder="Usuario" onChange={(e) => obtainUserData(e)} name="username" onBlur={() => CheckUsername(username)} />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Control size="lg" type="password" placeholder="Contraseña" onChange={(e) => obtainUserData(e)} name="password" />
            </Form.Group>
            <Form.Group controlId="verifyPassword">
              <Form.Control type="password" size="lg" placeholder="Verifique contraseña" onChange={(e) => obtainUserData(e)} name="confirmPassword" />
            </Form.Group>
            <Form.Group>
              <Button variant="primary" size="lg" type="submit" className="col-12" onClick={(e) => SignUpHandler(e)}>
                REGISTRARSE
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-center pb-5">
        <Col xs={11} sm={9} md={7} lg={6} xl={5} className="text-center">
          <p className="text_grey pb-2"><i>Ya estoy registrado {modal ? <span className='text_red' role='button' onClick={handleClickSignUp}><u>Ingresá</u></span> : <Link to={'/ingresa'} className="text_red"><u>Ingresá</u></Link>}</i></p>
        </Col>
      </Row>
    </Container >
  )
}

export default SignUpStructure
