import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Layout from '../layout';
// steps
import GeneralStepPurchase from './../structures/GeneralStepPurchase';
import GeneralStepSell from './../structures/GeneralStepSell';

const Steps = () => {
    const [typeStep, setTypeStep] = useState("comprar");
    return (
        <Layout>
            <header className="headerSteps d-flex align-items-center">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h1 className="text-center text_red font-weight-bold">¿Qué esperas para hacer el mejor negocio? Te acercamos las
                            mejores oportunidades en financiamiento</h1>
                        </Col>
                        <Col xs={12}>
                            <Row className="justify-content-center mt-5">
                                <Col xs={6} md={3} className="text-center">
                                    {typeStep === "vender"
                                        ? <Button disabled className="my-2" size="lg" variant="primary">Vender</Button>
                                        : <Button className="my-2" size="lg" variant="primary" onClick={() => setTypeStep("vender")}>Vender</Button>
                                    }

                                </Col>
                                <Col xs={6} md={3} className="text-center">
                                    {typeStep === "comprar"
                                        ? <Button disabled className="my-2" size="lg" variant="primary">Comprar</Button>
                                        : <Button className="my-2" size="lg" variant="primary" onClick={() => setTypeStep("comprar")}>Comprar</Button>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </header>
            {/* pasos */}
            {typeStep === "comprar" ? <GeneralStepPurchase /> : <GeneralStepSell />}
        </Layout>
    )
};

export default Steps;