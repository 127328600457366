import { VALIDATION_START, VALIDATION_SUCCESS, VALIDATION_FAIL } from './ActionTypes';

export function formValidationAction() {
    return dispatch => {
        dispatch(validationStart())
    }
}

export const validationStart = () => {
    return {
        type: VALIDATION_START
    };
};

export const validationSuccess = () => ({
    type: VALIDATION_SUCCESS
})

export const validationFail = () => ({
    type: VALIDATION_FAIL
})