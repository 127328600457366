import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

//Images
import SimulaIcon from './../../resources/images/icons/vehicle_icon.png'
import CompletaIcon from './../../resources/images/icons/list_icon.png'
import RecibiIcon from './../../resources/images/icons/check_icon.png'
import EnviaIcon from './../../resources/images/icons/contract_icon.png'
import CoordinamosIcon from './../../resources/images/icons/key_icon.png'

const GeneralStepSell = () => {
    return (
        <>
            <Container className="py-5">
                <Row>
                    <Col xs={12}>
                        <h1 className="text_red font-weight-bold text-center text-md-left mb-3">Quiero vender un usado</h1>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="bg_grey">
                <Container>
                    <Row className="d-flex justify-content-center align-items-center py-5">
                        <Col xs={6} md={3} lg={2} className="text-center text-md-left">
                            <Image fluid src={SimulaIcon} width="70%" alt="Nosotros icono" />
                        </Col>
                        <Col xs={11} md={9} lg={10} className="pt-4 pt-md-0">
                            <h2 className="text_red text-center text-md-left pb-3 font-weight-bold">Simulá </h2>
                            <p className=" text-md-justify">
                                el crédito, infórmate de todas las alternativas y descubrí cuál es la más
                                conveniente para vos.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid >
                <Container>
                    <Row className="d-flex justify-content-center align-items-center py-5">
                        <Col xs={6} md={3} lg={2} className="text-center text-md-left">
                            <Image fluid src={CompletaIcon} width="70%" alt="Nosotros icono" />
                        </Col>
                        <Col xs={11} md={9} lg={10} className="pt-4 pt-md-0">
                            <h2 className="text_red text-center text-md-left pb-3 font-weight-bold">Completá</h2>
                            <p className=" text-md-justify">
                                el formulario con toda la información necesaria para hacer la gestión de
                                crédito y disponer luego del aprobado sin costo. Nosotros los asistiremos vía
                                email, telefónica o chat con cualquier duda o consulta que tuviera

                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="bg_grey">
                <Container>
                    <Row className="d-flex justify-content-center align-items-center py-5">
                        <Col xs={6} md={3} lg={2} className="text-center text-md-left">
                            <Image fluid src={RecibiIcon} width="70%" alt="Nosotros icono" />
                        </Col>
                        <Col xs={11} md={9} lg={10} className="pt-4 pt-md-0">
                            <h2 className="text_red text-center text-md-left pb-3 font-weight-bold">Recibí</h2>
                            <p className=" text-md-justify">
                                dentro de las 24 horas el aprobado con los detalles del mismo y la
                                documentación que precisará completar, y a partir de allí usted ya está en
                                condiciones de cerrar el trato con el titular del vehículo que quiere comprar y
                                por el cual aprobaron el crédito.

                    </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid >
                <Container>
                    <Row className="d-flex justify-content-center align-items-center py-5">
                        <Col xs={6} md={3} lg={2} className="text-center text-md-left">
                            <Image fluid src={EnviaIcon} width="70%" alt="Nosotros icono" />
                        </Col>
                        <Col xs={11} md={9} lg={10} className="pt-4 pt-md-0">
                            <h2 className="text_red text-center text-md-left pb-3 font-weight-bold">Envía</h2>
                            <p className=" text-md-justify">
                                la documentación personal y los datos de contacto del vendedor y el vehículo
                                que compraste, y coordinamos con el vendedor la documentación y el chequeo
                                del vehículo
                    </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="bg_grey">
                <Container>
                    <Row className="d-flex justify-content-center align-items-center py-5">
                        <Col xs={6} md={3} lg={2} className="text-center text-md-left">
                            <Image fluid src={CoordinamosIcon} width="70%" alt="Nosotros icono" />
                        </Col>
                        <Col xs={11} md={9} lg={10} className="pt-4 pt-md-0">
                            <h2 className="text_red text-center text-md-left pb-3 font-weight-bold">Coordinamos</h2>
                            <p className=" text-md-justify">
                                con usted y el vendedor, el día y la hora para realizar la operación.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container>
                <Row className="my-5">
                    <Col>
                        <h3 className="mb-3 text_red font-weight-bold">Observaciones:</h3>

                        <h3>
                            <span className="text_red font-weight-bold">Tranquilidad con la documentación:</span> a usted le preocupa que el auto que está comprando no tenga
                            ningún impedimento en la documentación para realizar la transferencia a su nombre y a nosotros
                            disponer de la prenda para presentar al acreedor y recuperar el efectivo que le adelantamos. No
                            cabe duda que haremos todos los controles previos para asegurarnos la transferencia y estaremos
                            detrás de los trámites para recuperar nuestra inversión lo antes posible!
                         </h3>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default GeneralStepSell;