import { MODIFY_USER_START, MODIFY_USER_SUCCESS, MODIFY_USER_FAIL } from '../actions/ActionTypes';

const initialState = {
    success: false,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MODIFY_USER_START:
            return {
                ...state,
                success: false,
                error: null,
                loading: true
            }
        case MODIFY_USER_SUCCESS:
            return {
                ...state,
                success: true,
                error: null,
                loading: false
            }
        case MODIFY_USER_FAIL:
            return {
                ...state,
                success: false,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}