import { AUTH_START, AUTH_SUCCESS, AUTH_FAIL, LOGOUT } from './ActionTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../config/Axios';

export function GoogleAuthAction(data, history, modal) {
  return (dispatch) => {
    dispatch(authStart())
    clienteAxios.post('/users/google-login', data)
      .then((userData) => {
        dispatch(authSuccess(userData))
        if (!modal) {
          history.push('/');
        } else {
          modal();
          history.push('/solicita');
        }
      })
      .catch(error => {
        dispatch(authFail())
        if (!error.response) {
          Swal.fire({
            icon: 'error',
            title: 'Lo siento',
            text: 'Error de conexión.'
          })
        }
        else if (error.response.status === 400) {
          Swal.fire({
            icon: 'error',
            title: 'Lo siento',
            text: 'El email o la contraseña que ingresaste no coinciden, intenta de nuevo o recupera tu contraseña.'
            // text: 'Error de conexión.'
          })
        }
        else if (error.response.status === 401) {
          Swal.fire({
            icon: 'error',
            title: 'Lo siento',
            text: 'Error de autenticación.'
            // text: 'Error de conexión.'
          })
        }
      })
  }
}

export const logoutStart = () => ({
  type: LOGOUT
})

export const authStart = () => {
  return {
    type: AUTH_START
  };
};

export const authSuccess = (user) => ({
  type: AUTH_SUCCESS,
  payload: user
})

export const authFail = () => ({
  type: AUTH_FAIL,
})