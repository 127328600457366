import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import clienteAxios from '../../config/Axios'
import Swal from 'sweetalert2';
import Layout from '../layout/index';
//Actions
import { ModifyUserAction } from '../../actions/ModifyProfileAction';
import { formValidationAction, validationSuccess, validationFail } from '../../actions/ValidationAction';

const ModifyProfile = ({ history }) => {

    //READ INPUTS
    const [firstName, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    // RENAME DISPATCH
    const dispatch = useDispatch();

    //FUNCTIONS
    const token = useSelector((state) => state.auth.token);

    //SESION VALIDATION

    if (token === null) {
        history.push('/ingresa');
    } const config = {
        headers: { 'Authorization': "Bearer " + token }
    };

    //ACTIONS
    const formValidation = () => dispatch(formValidationAction());
    const successValidation = () => dispatch(validationSuccess());
    const failValidation = () => dispatch(validationFail());

    const modifyUser = (data, token, history) => dispatch(ModifyUserAction(data, token, history));

    //OBTAIN INFO FROM API TO DISPLAY
    const obtainUserFromAPI = async () => {
        clienteAxios.get('/users/me', config)
            .then((response) => {
                setName(response.data.firstName)
                setLastName(response.data.lastName)
                setUsername(response.data.username)
                setEmail(response.data.email)
                setPhone(response.data.phone)
            })
            .catch(error => {
            })
    }
    useEffect(
        () => {
            obtainUserFromAPI()
        }, []
    )


    //OBTAIN INFO FROM INPUT, VALIDATE AND SEND INFO TO API
    const obtainUserData = e => {
        if (e.target.name === "firstName") {
            setName(e.target.value)
        } else if (e.target.name === "lastName") {
            setLastName(e.target.value)
        } else if (e.target.name === "email") {
            setEmail(e.target.value)
        } else if (e.target.name === "phone") {
            setPhone(e.target.value)
        } else if (e.target.name === "password") {
            setPassword(e.target.value)
        } else if (e.target.name === "confirmPassword") {
            setConfirmPassword(e.target.value)
        }
    }


    const ModifyProfileHandler = e => {
        e.preventDefault();
        formValidation()
        if (firstName.trim() === '' || lastName.trim() === '' || email.trim() === '' || phone.trim() === '') {
            failValidation();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Campos incompletos'
            })
            return;
        }
        successValidation()
        const data = {
            firstName,
            lastName,
            email,
            phone
        }
        modifyUser(data, token, history)
    }

    const ModifyPasswordHandler = e => {
        e.preventDefault();
        formValidation()
        if (password.trim() === '' || confirmPassword.trim() === '') {
            failValidation();
            Swal.fire({
                icon: 'error',
                title: 'Lo siento',
                text: 'Campos incompletos'
            })
            return;
        }
        if (password.length < 8) {
            failValidation();
            Swal.fire({
                icon: 'error',
                title: 'Lo siento',
                text: 'La contraseña debe contener al menos ocho (8) dígitos'
            })
            return;
        }
        if (password.trim() !== confirmPassword.trim()) {
            failValidation();
            Swal.fire({
                icon: 'error',
                title: 'Lo siento',
                text: 'La contraseña debe coincidir'
            })
            return;
        }
        successValidation()
        const data = {
            password
        }
        modifyUser(data, token, history)
    }

    return (
        <Layout>
            <Container>
                <Row className="justify-content-center py-5">
                    <Col xs={12} className="text-center">
                        <h1 className="text_red font-weight-bold">Modificar Perfil</h1>
                    </Col>
                </Row>
                <Row className="justify-content-center pb-3">
                    <Col xs={11} sm={9} md={7} lg={6} xl={5}>
                        <Form>
                            <Form.Group controlId="email">
                                <Form.Control type="email" placeholder="Correo electrónico" onChange={(e) => obtainUserData(e)} name="email" value={email || ""} />
                            </Form.Group>
                            <Form.Group controlId="name">
                                <Form.Control type="text" placeholder="Nombre" onChange={(e) => obtainUserData(e)} name="firstName" value={firstName || ""} />
                            </Form.Group>
                            <Form.Group controlId="lastName">
                                <Form.Control type="text" placeholder="Apellido" onChange={(e) => obtainUserData(e)} name="lastName" value={lastName || ""} />
                            </Form.Group>
                            <Form.Group controlId="phoneNumber">
                                <Form.Control type="phone" placeholder="Teléfono" onChange={(e) => obtainUserData(e)} name="phone" value={phone || ""} />
                            </Form.Group>
                            <Form.Group controlId="username">
                                <Form.Control type="text" placeholder="Usuario" disabled value={username || ""} />
                            </Form.Group>
                            <Form.Group>
                                <Button onClick={(e) => ModifyProfileHandler(e)} variant="primary" type="submit" className="col-12" >
                                    MODIFICAR
                                </Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row className="justify-content-center pb-3">
                    <Col xs={11} sm={9} md={7} lg={6} xl={5}>
                        <hr />
                    </Col>
                </Row>
                <Row className="justify-content-center pb-5">
                    <Col xs={12} className="text-center">
                        <h1 className="text_red font-weight-bold">Modificar Contraseña</h1>
                    </Col>
                </Row>
                <Row className="justify-content-center pb-3">
                    <Col xs={11} sm={9} md={7} lg={6} xl={5}>
                        <Form.Group controlId="password">
                            <Form.Control type="password" placeholder="Contraseña" onChange={(e) => obtainUserData(e)} name="password" />
                        </Form.Group>
                        <Form.Group controlId="verifyPassword">
                            <Form.Control type="password" placeholder="Verifique contraseña" onChange={(e) => obtainUserData(e)} name="confirmPassword" />
                        </Form.Group>
                        <Form.Group className="m-0 pb-5">
                            <Button onClick={(e) => ModifyPasswordHandler(e)} variant="primary" type="submit" className="col-12" >
                                MODIFICAR
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </Container >
        </Layout>
    )
};

export default ModifyProfile;