import React from 'react';
import { Row, Col, Tab, Nav } from "react-bootstrap";
const StepPurchase = () => {
    return (
        <>
            <p className="my-5">Ofrecé y vendé mejor tu vehículo financiado, siguiendo estos 5 pasos.</p>
            <Row>
                <Col xs={12} className="pt-3 pb-3 pb-md-5">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="Ofrece">
                        <Row className="justify-content-center">
                            <Col sm={12} className="pb-3">
                                <Tab.Content className="col-12 col-md-9 m-auto px-0 text-center position-relative">
                                    <Tab.Pane eventKey="Ofrece">
                                        <div className="h-100 d-flex align-items-center text-center justify-content-center">
                                            <Col xs={12} className="bg_icon encontra py-5">
                                                <h3 className="pb-3 text_red font-weight-bold">Ofrecé</h3>
                                                <p>tu vehículo de forma financiada. Puedes agregar el enlace tefinancio.com.ar a tu publicación para facilitarle las cosas al comprador.</p>
                                            </Col>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Contactaremos">
                                        <div className="h-100 d-flex align-items-center text-center justify-content-center">
                                            <Col xs={12} className="bg_icon reserva py-5">
                                                <h3 className="pb-3 text_red font-weight-bold">Contactaremos</h3>
                                                <p>a tu comprador y tomador del crédito, y ni bien recibamos la información gestionaremos la aprobación  del crédito.</p>
                                            </Col>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Asistiremos">
                                        <div className="h-100 d-flex align-items-center text-center justify-content-center">
                                            <Col xs={12} className="bg_icon paga py-5">
                                                <h3 className="pb-3 text_red font-weight-bold">Asistiremos</h3>
                                                <p> a usted para completar la documentación habitual del vehículo que se precisa, inclusive podemos realizar gran parte de esos trámites nosotros.</p>
                                            </Col>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Chequeamos">
                                        <div className="h-100 d-flex align-items-center text-center justify-content-center">
                                            <Col xs={12} className="bg_icon inscribi py-5">
                                                <h3 className="pb-3 text_red font-weight-bold">Chequeamos</h3>
                                                <p>la documentación y realizaremos el control del vehículo, y coordinamos día y horario con usted y el  comprador del vehículo para concluir su negocio.</p>
                                            </Col>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Pagaremos">
                                        <div className="h-100 d-flex align-items-center text-center justify-content-center">
                                            <Col xs={12} className="bg_icon retira py-5">
                                                <h3 className="pb-3 text_red font-weight-bold">Pagaremos</h3>
                                                <p>a usted en efectivo o con transferencia bancaria y nos ocupamos de realizar la transferencia registral del vehículo. En el mismo momento de recibido el pago entrega la unidad a su comprador y firman la documentación pertinente.</p>
                                            </Col>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                            <Col sm={10} className="my-4">
                                <Nav variant="pills" className="d-flex align-items-center justify-content-between col-12 col-md-9 m-auto px-0">
                                    <Nav.Item>
                                        <Nav.Link eventKey="Ofrece">1</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Contactaremos">2</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Asistiremos">3</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Chequeamos">4</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Pagaremos">5</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <p className="mb-3">
                        <span className="text_red font-weight-bold">Seguro:</span> cobra en el mismo momento que entrega el vehículo y la documentación.
                    </p>
                    <p>
                        <span className="text_red font-weight-bold">Tranquilidad con transferencia:</span> a usted le preocupa que no circule a su nombre el vehículo y a nosotros
                        disponer de la prenda para presentar al acreedor y recuperar el efectivo que le adelantamos. No cabe duda
                        que estaremos detrás de los trámites de transferencia para recuperar nuestra inversión lo antes posible!
                    </p>
                </Col>
            </Row>
        </>
    );
}

export default StepPurchase;