import React from 'react';
import { Row, Col, Tab, Nav } from "react-bootstrap";
const StepPurchase = () => {
    return (
        <>
            <p className="my-5">Elija su próximo vehículo, negocie el precio de contado y compre en estos 5 pasos.</p>
            <Row>
                <Col xs={12} className="pt-3 pb-3 pb-md-5">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="Simula">
                        <Row>
                            <Col sm={12} className="pb-3">
                                <Tab.Content className="col-12 col-md-9 m-auto px-0 text-center position-relative">
                                    <Tab.Pane eventKey="Simula">
                                        <div className="h-100 d-flex align-items-center text-center justify-content-center">
                                            <Col xs={12} className="bg_icon simula py-5">
                                                <h3 className="pb-3 text_red font-weight-bold">Simulá</h3>
                                                <p> el crédito, infórmate de todas las alternativas y descubrí cuál es la más
                                                    conveniente para vos</p>
                                            </Col>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Completa">
                                        <div className="h-100 d-flex align-items-center text-center justify-content-center">
                                            <Col xs={12} className="py-5 bg_icon reserva">
                                                <h3 className="pb-3 text_red font-weight-bold">Completá</h3>
                                                <p>el formulario con toda la información necesaria para hacer la gestión de crédito y disponer luego del  aprobado sin costo. Nosotros lo asistiremos por teléfono o Whatsapp con cualquier duda o consulta  que tuviera.</p>
                                            </Col>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Recibi">
                                        <div className="h-100 d-flex align-items-center text-center justify-content-center">
                                            <Col xs={12} className="py-5 bg_icon paga">
                                                <h3 className="pb-3 text_red font-weight-bold">Recibí</h3>
                                                <p>en forma online o en las próximas horas la calificación y aprobación del crédito, y a partir de allí usted  ya está en condiciones de cerrar el trato con el titular del vehículo que quiere comprar. </p>
                                            </Col>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Envia">
                                        <div className="h-100 d-flex align-items-center text-center justify-content-center">
                                            <Col xs={12} className="py-5 bg_icon inscribi">
                                                <h3 className="pb-3 text_red font-weight-bold">Envía</h3>
                                                <p>los datos de contacto del vendedor del vehículo que está por adquirir, y nos contactamos con él para  avanzar con la documentación y el chequeo del vehículo.</p>
                                            </Col>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Coordinamos">
                                        <div className="h-100 d-flex align-items-center text-center justify-content-center">
                                            <Col xs={12} className="py-5 bg_icon retira">
                                                <h3 className="pb-3 text_red font-weight-bold">Coordinamos</h3>
                                                <p>con usted y el vendedor, el día y la hora para realizar la operación.</p>
                                            </Col>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                            <Col sm={12} className="my-4">
                                <Nav variant="pills" className="d-flex align-items-center justify-content-between col-12 col-md-9 m-auto px-0">
                                    <Nav.Item>
                                        <Nav.Link eventKey="Simula">1</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Completa">2</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Recibi">3</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Envia">4</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Coordinamos">5</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <p>
                        <span className="text_red font-weight-bold">Tranquilidad con la documentación:</span> a usted le preocupa que el auto que está comprando no tenga ningún
                        impedimento en la documentación para realizar la transferencia a su nombre y a nosotros disponer de la
                        prenda para presentar al acreedor y recuperar el efectivo que le adelantamos. No cabe duda que haremos
                        todos los controles previos para asegurarnos la transferencia y estaremos detrás de los trámites para
                        recuperar nuestra inversión lo antes posible!
                    </p>
                </Col>
            </Row>
        </>
    );
}

export default StepPurchase;