import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from "react-router-dom";

// imagen
import imageNoFound from './../resources/images/page/nofound.png'
const NotFound = () => {
	return (
		<Container>
			<Row className="justify-content-center">
				<Col xs={12} sm={8} md={6}>
					<Image fluid src={imageNoFound} />
				</Col>
			</Row>
			<h1 className="text-center text_red font-weight-bold">LO SENTIMOS</h1>
			<h3 className="text-center my-3">No hemos encontrado la página que buscas.</h3>
			<Row className="justify-content-center my-5">
				<Col xs={12} sm={8} md={5}>
					<Link className="w-100 btn btn-primary" to={'/'}>REGRESAR</Link>
				</Col>
			</Row>

		</Container>
	);
}

export default NotFound;