import { SIGN_UP_START, SIGN_UP_SUCCESS, SIGN_UP_FAIL } from '../actions/ActionTypes';

const initialState = {
    success: false,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SIGN_UP_START:
            return {
                ...state,
                success: false,
                error: null,
                loading: true
            }
        case SIGN_UP_SUCCESS:
            return {
                ...state,
                success: true,
                error: null,
                loading: false
            }
        case SIGN_UP_FAIL:
            return {
                ...state,
                success: false,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}