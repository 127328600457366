import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import debounce from "lodash.debounce"
import { useDispatch } from 'react-redux'
import { Container, Row, Col, Form } from 'react-bootstrap';
import clienteAxios from '../../../config/Axios';
import { SimulatorAction } from '../../../actions/SimulatorAction'
import { clearUserCredit } from '../../../actions/SetUserCreditAction'
import Opp0kmLogo from '../../../resources/images/logo/0km.png'
import queryString from 'query-string';
import AutoSuggest from 'react-autosuggest';
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";

const FilterSelect = ({ history }) => {
    const platformQuery = 'platform=oportunidades0km';
    const location = history.location
    const searchParams = new URLSearchParams(location.search);
    const storeCapitalLimit = useSelector((state) => state.simulator.capitalLimit);
    const firstRender = useRef(true);
    const [brands, setBrands] = useState([]);
    const [brandsHashtable, setBrandsHashtable] = useState({});
    const [modelsHashtable, setModelsHashtable] = useState({});
    const [vehiclesHashtable, setVehiclesHashtable] = useState({});
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [vehicleTypesHashtable, setVehicleTypesHashtable] = useState([]);
    const [suggestValue, setSuggestValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionSelected, setSuggestionSelected] = useState(null);
    const [formdata, setFormData] = useState({
        brand: '',
        model: '',
        year: searchParams.has('year') ? searchParams.get('year') : '',
        version: '',
        vehicleType: '',
        capital: searchParams.has('capital') ? searchParams.get('capital') : 100000,
    });
    const [queries, setQueries] = useState({
        brand: searchParams.has('brand') ? searchParams.get('brand') : undefined,
        model: searchParams.has('model') ? searchParams.get('model') : undefined,
        year: searchParams.has('year') ? searchParams.get('year') : undefined,
        version: searchParams.has('version') ? searchParams.get('version') : undefined,
        vehicleType: searchParams.has('vehicleType') ? searchParams.get('vehicleType') : undefined,
        capital: searchParams.has('capital') ? searchParams.get('capital') : undefined,
        referencePrice: searchParams.has('referencePrice') ? searchParams.get('referencePrice') : undefined
    });
    //Filter Results
    const [filterModels, setFilterModels] = useState([]);
    const [filterVehicle, setFilterVehicle] = useState([]);
    const [filterYears, setFilterYears] = useState([]);
    const [referencePrice, setReferencePrice] = useState(searchParams.has('referencePrice') ? searchParams.get('referencePrice') : "1000000");
    const [capitallimit, setCapitalLimit] = useState(800000);

    //DISPATCH
    const dispatch = useDispatch();

    //ACTIONS
    const simulatorAction = (data, platformQuery) => dispatch(SimulatorAction(data, platformQuery))
    const setUserCreditAction = () => dispatch(clearUserCredit())

    const { brand, model, year, version, vehicleType, capital } = formdata;

    //Functions to obtain api results
    const obtainBrandsFromAPI = async () => {
        const res = await clienteAxios.get(`/brand?${platformQuery}`);

        return res.data.brands;
    }

    const obtainModelsFromAPI = async (brandId) => {
        const res = await clienteAxios.get(`/model?${platformQuery}&brandId=${brandId}`);

        return res.data.models;
    }

    const obtainYearsFromAPI = async (modelId) => {
        const res = await clienteAxios.get(`/model-years?${platformQuery}&modelId=${modelId}`);

        return res.data.years;
    }

    const obtainVehicleFromAPI = async (modelId, year) => {
        const res = await clienteAxios.get(`/vehicle?${platformQuery}&modelId=${modelId}&year=${year}`);

        return res.data.vehicles;
    }

    const obtainVehicleTypeFromAPI = async () => {
        const res = await clienteAxios.get(`/vehicle-type?${platformQuery}`);

        return res.data.vehiclesTypeDB;
    }

    const obtainVehiclesBySearch = async (search) => {
        const res = await clienteAxios.get(`/search-vehicles?${platformQuery}&search=${search}`);

        return res.data.vehicles;
    }

    //Function to format an ammount value
    const formatPrice = (value) => {
        return new Intl.NumberFormat('de-DE').format(parseInt(value))
    }

    //Debounce function to avoid unnecesary requests
    // eslint-disable-next-line
    const debouncedUli = React.useMemo(() => debounce(simulatorAction, 300), [])

    //Form Submit
    const formSubmit = (e) => {
        const data = {
            brand: formdata.brand === '' ? null : formdata.brand,
            model: formdata.model === '' ? null : formdata.model,
            year: formdata.year === '' ? null : formdata.year,
            version: formdata.version === '' ? null : formdata.version,
            vehicleType: formdata.vehicleType === '' ? null : formdata.vehicleType,
            price: referencePrice,
            capital: formdata.capital
        }
        setUserCreditAction({})
        debouncedUli(data, platformQuery);
    }

    //Function to position the bublle on range input
    function setbubble0km(range, bubble0km) {
        const val = range.value
        const min = range.min ? range.min : 0;
        const max = range.max ? range.max : 100;
        const newVal = Number(((val - min) * 100) / (max - min));

        // Sorta magic numbers based on size of the native UI thumb
        bubble0km.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
    }

    //Function to read form data
    const readFormData = (e) => {
        if (e.target.name === 'brand') {
            setFormData({
                ...formdata,
                [e.target.name]: e.target.value,
                model: '',
                year: '',
                version: '',
                vehicleType: '',
                capital: 100000,
            });
            setReferencePrice("1000000");
            setQueries({
                ...queries,
                [e.target.name]: brandsHashtable[e.target.value] || undefined,
                model: undefined,
                year: undefined,
                version: undefined,
                vehicleType: undefined,
                capital: undefined,
                referencePrice: undefined
            })
            setSuggestValue("")
            setSuggestionSelected(null)
            setSuggestions([])
        } else if (e.target.name === 'model') {
            setFormData({
                ...formdata,
                [e.target.name]: e.target.value,
                year: '',
                version: '',
                vehicleType: '',
                capital: 100000
            })
            setReferencePrice("1000000");
            setQueries({
                ...queries,
                [e.target.name]: modelsHashtable[e.target.value] || undefined,
                year: undefined,
                version: undefined,
                vehicleType: undefined,
                capital: undefined,
                referencePrice: undefined
            })
            setSuggestValue("")
            setSuggestionSelected(null)
            setSuggestions([])
        } else if (e.target.name === 'year') {
            setFormData({
                ...formdata,
                [e.target.name]: e.target.value,
                version: '',
                vehicleType: '',
                capital: 100000
            })
            setReferencePrice("1000000");
            setQueries({
                ...queries,
                [e.target.name]: e.target.value || undefined,
                version: undefined,
                vehicleType: undefined,
                capital: undefined,
                referencePrice: undefined
            })
            setSuggestValue("")
            setSuggestionSelected(null)
            setSuggestions([])
        } else if (e.target.name === 'version') {
            setFormData({
                ...formdata,
                [e.target.name]: e.target.value
            });
            setQueries({
                ...queries,
                [e.target.name]: vehiclesHashtable[e.target.value] || undefined
            })
            setSuggestValue("")
            setSuggestionSelected(null)
            setSuggestions([])
        } else if (e.target.name === 'vehicleType') {
            setFormData({
                ...formdata,
                [e.target.name]: e.target.value
            });
            setQueries({
                ...queries,
                [e.target.name]: vehicleTypesHashtable[e.target.value] || undefined
            })
            setSuggestValue("")
            setSuggestionSelected(null)
            setSuggestions([]);
        } else {
            setFormData({
                ...formdata,
                [e.target.name]: e.target.value
            });
            setQueries({
                ...queries,
                [e.target.name]: e.target.value || undefined
            })
        }
    }

    const onSuggestionsFetchRequested = async (value) => {
        try {
            const suggestions = await obtainVehiclesBySearch(value);

            setSuggestions(suggestions);
        } catch (err) {
            setSuggestions([]);
        }
    }

    const onSuggestionsFetchRequestedDebounced = React.useMemo(() => debounce(onSuggestionsFetchRequested, 500), [])

    const setVehicleFromSuggester = (suggestionValue) => {
        const vehicle = suggestions.find(suggestion => suggestionValue === suggestion.vehicle);

        setFormData({
            ...formdata,
            brand: vehicle.brand_id._id,
            model: '',
            year: '',
            version: '',
            vehicleType: '',
            capital: 100000,
        })
        setQueries({
            ...queries,
            brand: brandsHashtable[vehicle.brand_id._id],
            model: '',
            year: '',
            version: '',
            vehicleType: undefined,
            capital: undefined,
            referencePrice: undefined
        })
        setReferencePrice("1000000");
        setSuggestionSelected(vehicle);
        setSuggestValue(suggestionValue)
    }
    //Obtain APIS
    const obtainAllBrands = async () => {
        const allBrands = await obtainBrandsFromAPI()
        const allBrandsHT = allBrands.reduce((prev, current) => {

            prev[current.name] = current._id;
            prev[current._id] = current.name;

            return prev;
        }, {})

        setBrands(allBrands);
        setBrandsHashtable(allBrandsHT);
    }
    const obtainAllVehicleTypes = async () => {
        const vehicleTypes = await obtainVehicleTypeFromAPI();
        const vehicleTypesHT = vehicleTypes.reduce((prev, current) => {
            prev[current.name] = current._id;
            prev[current._id] = current.name;

            return prev;
        }, {})

        setVehicleTypes(vehicleTypes)
        setVehicleTypesHashtable(vehicleTypesHT)
    }
    useEffect(() => {
        obtainAllBrands();
        obtainAllVehicleTypes();
    }, []);

    //Filters Effects
    useEffect(() => {
        if (brand === '') return;
        const obtainModelsByBrandId = async () => {
            try {
                const models = await obtainModelsFromAPI(brand)
                const modelsHT = models.reduce((prev, current) => {
                    prev[`${current.name}-${current.brand_id}`] = current._id;
                    prev[current._id] = current.name;

                    return prev;
                }, {});

                setFilterModels(models);
                setModelsHashtable(modelsHT);
            } catch (err) {

            }
        }
        obtainModelsByBrandId()
    }, [formdata.brand]);

    useEffect(() => {
        if (model === '') return;
        const obtainYearByModel = async () => {
            try {
                const years = (await obtainYearsFromAPI(model)).map(years => years._id);

                setFilterYears(years)
            } catch (err) {
                console.log(err);
            }
        }
        obtainYearByModel();
    }, [formdata.model])

    useEffect(() => {
        if (model === '' || year === '') return;
        const obtainVehiclesByModel = async () => {
            try {
                const vehicles = await obtainVehicleFromAPI(model, year);
                const vehiclesHT = vehicles.reduce((prev, current) => {
                    prev[`${current.version_name}-${current.model_id}-${current.versionYearPriceRelation}`] = current._id;
                    prev[current._id] = current.version_name;

                    return prev;
                }, {});

                setFilterVehicle(vehicles);
                setVehiclesHashtable(vehiclesHT);
            } catch (err) {
                console.log(err.response);
            }
        }
        obtainVehiclesByModel();
    }, [formdata.model, formdata.year]);

    useEffect(() => {
        const obtainReferencePrice = async () => {
            const priceReferenceFilter = filterVehicle.filter(vehicle => vehicle._id === formdata.version);


            if (priceReferenceFilter.length !== 0) {
                setReferencePrice(priceReferenceFilter[0].price)
            }
        }
        obtainReferencePrice();
    }, [formdata.version]);

    useEffect(() => {
        if (!storeCapitalLimit) return
        setCapitalLimit(storeCapitalLimit);
    }, [storeCapitalLimit])

    useEffect(() => {
        formSubmit()
    }, [brand, capital, referencePrice, vehicleType, year]);

    useEffect(() => {
        const bubble0km = () => {
            const range = document.querySelector(".range");
            const bubble0km = document.querySelector(".bubble0km");

            setbubble0km(range, bubble0km);
        }

        bubble0km()
    }, [brand, model, year, capitallimit])

    //Clear debounced function
    useEffect(() => {
        return () => {
            debouncedUli.cancel();
            onSuggestionsFetchRequestedDebounced.cancel();
        }
    }, []);

    useEffect(() => {
        const changeUrl = () => {
            const qs = queryString.stringify(queries)
            const url = new URL(window.location.href);
            url.search = qs;

            if (url.search) {
                url.hash = "simulador";
            } else {
                url.hash = "";
            }
            window.history.replaceState({}, null, url.toString())
        }
        changeUrl();
    }, [queries]);

    const isObjectNotEmpty = (object) => Object.keys(object).length !== 0;

    useEffect(() => {
        const setUrlData = () => {
            let brandId;

            if (searchParams.has('brand') && isObjectNotEmpty(brandsHashtable)) {
                brandId = brandsHashtable[decodeURIComponent(searchParams.get('brand'))]

                setFormData({
                    ...formdata,
                    brand: brandId ?? brand
                })
            }
        }
        setUrlData()
    }, [brandsHashtable]);

    useEffect(() => {
        const setUrlData = () => {
            let modelId;
            let brandId;

            if (suggestionSelected) return;

            if (searchParams.has('brand') && isObjectNotEmpty(brandsHashtable)) {
                brandId = brandsHashtable[decodeURIComponent(searchParams.get('brand'))]
            }

            if (searchParams.has('model') && brandId && isObjectNotEmpty(modelsHashtable)) {
                modelId = modelsHashtable[`${decodeURIComponent(searchParams.get('model'))}-${brandId}`];

                setFormData({
                    ...formdata,
                    model: modelId ?? model
                })
            }
        }
        const setModelBySuggestionSelected = () => {
            if (!suggestionSelected) return;

            setFormData({
                ...formdata,
                model: suggestionSelected.model_id._id,
            })
            setQueries({
                ...queries,
                model: modelsHashtable[suggestionSelected.model_id._id],
            })
        }
        setModelBySuggestionSelected();
        setUrlData()

    }, [modelsHashtable, suggestionSelected]);

    useEffect(() => {
        if (filterYears.length === 0) return;
        const setYearBySuggestionSelected = () => {
            if (!suggestionSelected) return;

            setFormData({
                ...formdata,
                year: suggestionSelected.versionYearPriceRelation,
            })
            setQueries({
                ...queries,
                year: suggestionSelected.versionYearPriceRelation
            })
        }
        setYearBySuggestionSelected();
    }, [filterYears])

    useEffect(() => {
        const setUrlData = () => {
            let versionId;
            let modelId;
            let brandId;

            if (suggestionSelected) return;

            if (searchParams.has('brand') && isObjectNotEmpty(brandsHashtable)) {
                brandId = brandsHashtable[decodeURIComponent(searchParams.get('brand'))]
            }

            if (searchParams.has('model') && brandId && isObjectNotEmpty(modelsHashtable)) {
                modelId = modelsHashtable[`${decodeURIComponent(searchParams.get('model'))}-${brandId}`]
            }

            if (searchParams.has('version') && modelId && isObjectNotEmpty(vehiclesHashtable)) {
                versionId = vehiclesHashtable[`${decodeURIComponent(searchParams.get('version'))}-${modelId}-${searchParams.get('year')}`];

                setFormData({
                    ...formdata,
                    version: versionId ?? version,
                })
            }
        }
        const setVersionBySuggestionSelected = () => {
            if (!suggestionSelected) return;

            setFormData({
                ...formdata,
                version: suggestionSelected._id
            })
            setQueries({
                ...queries,
                version: vehiclesHashtable[suggestionSelected._id]
            })
        }
        setVersionBySuggestionSelected();
        setUrlData()
    }, [vehiclesHashtable]);

    useEffect(() => {
        const setUrlData = () => {
            let vehicleTypeId;

            if (searchParams.has('vehicleType') && isObjectNotEmpty(vehicleTypesHashtable)) {
                vehicleTypeId = vehicleTypesHashtable[decodeURIComponent(searchParams.get('vehicleType'))]

                setFormData({
                    ...formdata,
                    vehicleType: vehicleTypeId ?? vehicleType
                })
            }
        }
        setUrlData()
    }, [vehicleTypesHashtable]);

    return (
        <>
            <Container id="simulaTuCredito">
                <Row className='justify-content-between align-items-center flex-row-reverse'>
                    <Col md={3}>
                        <img src={Opp0kmLogo} alt="oportunidades0km" className="img-fluid mb-4 mb-sm-0" />
                    </Col>
                    <Col md={6}>
                        <h2 className="text_red font-weight-bold text-center text-md-left color-portunidades0km">Simulá tu crédito</h2>
                    </Col>
                </Row>
                <p className="mb-5 text-center text-sm-left">Defina primero el vehículo a financiar:</p>
                <Form className="my-4">
                    <Row>
                        <Col xs={12} className="filter">
                            <Form.Group controlId="search" className="d-flex align-items-center col-12 col-md-8 col-lg-4 pl-0">
                                <Form.Label className="m-0"><i className="fas fa-search"></i></Form.Label>
                                <AutoSuggest
                                    suggestions={suggestions}
                                    onSuggestionsClearRequested={() => setSuggestions([])}
                                    onSuggestionsFetchRequested={({ value }) => onSuggestionsFetchRequestedDebounced(value)}
                                    onSuggestionSelected={(_, { suggestionValue }) => setVehicleFromSuggester(suggestionValue)}
                                    getSuggestionValue={suggestion => suggestion.vehicle}
                                    renderSuggestion={suggestion => <span>{suggestion.vehicle}</span>}
                                    inputProps={{
                                        placeholder: "Buscá tu vehiculo",
                                        value: suggestValue,
                                        className: "pl-1 col-12 form-control",
                                        onChange: (_, { newValue, method }) => {
                                            setSuggestValue(newValue);
                                        }
                                    }}
                                    highlightFirstSuggestion={true}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="justify-content-start">
                        <Col xs={6} sm={6} md={3} lg={2}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label className="text_white">-</Form.Label>
                                <Form.Control as="select" className="opp0km" name="brand" value={brand} onChange={readFormData}>
                                    <option value=''>Marca</option>
                                    {brands.map(brand => (
                                        <option key={brand._id} value={brand._id}>{brand.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={2}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label className="text_white">-</Form.Label>
                                <Form.Control as="select" className="opp0km" name="model" value={model} disabled={formdata.brand ? false : true} onChange={readFormData}>
                                    <option value=''>Modelo</option>
                                    {filterModels.map(model => (
                                        <option key={model._id} value={model._id}>{model.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={2}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label className="text_white">-</Form.Label>
                                <Form.Control as="select" className="opp0km" name="year" value={year} disabled={formdata.model ? false : true} onChange={readFormData}>
                                    <option value='' >Año</option>
                                    {filterYears.map(year => (
                                        <option key={year}>{year}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={2}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label className="text_white">-</Form.Label>
                                <Form.Control as="select" className="opp0km" disabled={formdata.model && formdata.year ? false : true} name="version" value={version} onChange={readFormData}>
                                    <option value=''>Versión</option>
                                    {filterVehicle.map(vehicle => (
                                        <option key={vehicle._id} value={vehicle._id}>{vehicle.version_name} </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={2}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label className="text_white">-</Form.Label>
                                <Form.Control type="text" name="referencePrice" className="opp0km" placeholder="Precio de referencia" autoComplete="off" onChange={(e) => {
                                    setReferencePrice(e.target.value);
                                    // setQueries({
                                    //     ...queries,
                                    //     [e.target.name]: e.target.value || undefined
                                    // })
                                }} value={referencePrice} />
                            </Form.Group>
                        </Col>
                        <Col xs={6} sm={6} md={3} lg={2}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label className="text_white">-</Form.Label>
                                <Form.Control as="select" name="vehicleType" className="opp0km" value={vehicleType} onChange={readFormData} disabled={formdata.version ? false : true} >
                                    <option value=''>Tipo de uso</option>
                                    {vehicleTypes.map(vehicleType => (
                                        <option key={vehicleType._id} value={vehicleType._id}>{vehicleType.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* <Row className="justify-content-between d-flex align-items-end align-items-md-center mb-5">

                    </Row> */}
                    <p className="text-center text-md-left mb-5 mb-lg-4">Elige el capital a financiar:</p>
                    <Form.Row className="justify-content-center">
                        <Col xs={8} className="mt-4">
                            <Form.Group controlId="formBasicRangeCustom">
                                <Form.Control value={capital} name="capital" step="10000" max={capitallimit} min={100000} className="range opp0km" onChange={readFormData} onInput={() => {
                                    const range = document.querySelector(".range");
                                    const bubble0km = document.querySelector(".bubble0km");

                                    setbubble0km(range, bubble0km);
                                }} type="range" custom />
                                <output className="bubble0km">
                                    <Form.Control className='range-input' type="text" value={firstRender.current ? `$ ${formatPrice(capital)}` : capital} name="capital"
                                        onBlur={(e) => {
                                            e.target.value = capital !== "" ? `$ ${formatPrice(capital)}` : 100000;
                                            const range = document.querySelector(".range");
                                            const bubble = document.querySelector(".bubble0km");
                                            range.value = e.target.value;
                                            setbubble0km(range, bubble);
                                        }}
                                        onFocus={(e) => {
                                            e.target.value = capital;
                                            firstRender.current = false;
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value > capitallimit) e.target.value = capitallimit
                                            if (e.target.value < 0) e.target.value = 100000
                                            const range = document.querySelector(".range");
                                            const bubble = document.querySelector(".bubble0km");
                                            range.value = e.target.value;
                                            setbubble0km(range, bubble);
                                            readFormData(e)
                                        }} />
                                </output>

                                <Form.Row>
                                    <Col className="text-left">
                                        <Form.Label >{`$ ${formatPrice(100000)}`}</Form.Label>
                                    </Col>
                                    <Col className="text-right">
                                        <Form.Label >{`$ ${formatPrice(capitallimit)}`}</Form.Label>
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                        </Col>
                    </Form.Row>
                </Form>
                {/* <Row className="justify-content-center">
                    <Col xs={6} sm={6} md={4} lg={2}>
                        <Button className="my-2 w-100" variant="primary" type="button" onClick={(e) => {
                            formSubmit(e);
                        }}>Simulá</Button>
                    </Col>
                    
                </Row> */}
                <Row>
                    <Col xs={12} className="d-flex align-items-center justify-content-start pt-4">
                        <p>
                            Compartir
                        </p>
                    </Col>
                    <Col xs={5} sm={4} className="d-flex align-items-center justify-content-between pt-2">
                        <p><WhatsappShareButton url={window.location.href} title={`${brandsHashtable[brand]} - ${modelsHashtable[model]} - ${year} - ${vehiclesHashtable[version]} - ${referencePrice} - ${vehicleTypesHashtable[vehicleType]} - ${capital}`} separator={" - "}><i className="fab fa-whatsapp .share_link_op0km"></i></WhatsappShareButton></p>
                        <br />
                        <p><FacebookShareButton url={window.location.href} quote={`${brandsHashtable[brand]} - ${modelsHashtable[model]} - ${year} - ${vehiclesHashtable[version]} - ${referencePrice} - ${vehicleTypesHashtable[vehicleType]} - ${capital}`} hashtag={"#oportunidades0km"}><i className="fab fa-facebook-f .share_link_op0km"></i></FacebookShareButton></p>
                        <br />
                        <p><TwitterShareButton url={window.location.href} title={`${brandsHashtable[brand]} - ${modelsHashtable[model]} - ${year} - ${vehiclesHashtable[version]} - ${referencePrice} - ${vehicleTypesHashtable[vehicleType]} - ${capital}` + " - " + window.location.href} via={"oportunidades0km"}><i className="fab fa-twitter .share_link_op0km"></i></TwitterShareButton></p>
                        <br />
                        <p><EmailShareButton url={window.location.href} subject={`${brandsHashtable[brand]} - ${modelsHashtable[model]} - ${year} - ${vehiclesHashtable[version]} - ${referencePrice} - ${vehicleTypesHashtable[vehicleType]} - ${capital}`}><i className="fas fa-envelope .share_link_op0km"></i></EmailShareButton></p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default FilterSelect;