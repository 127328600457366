import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux';
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap';
import lineRed from './../../resources/images/page/lineRed.jpg';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/Axios';
import Layout from '../layout/index'
//ACTIONS
import { formValidationAction, validationFail, validationSuccess } from '../../actions/ValidationAction';
import { clearSimulator } from '../../actions/SimulatorAction'
import { clearUserCredit } from '../../actions/SetUserCreditAction'

const Request = ({ history }) => {
    const creditSelected = useSelector(state => state.setUserCredit.creditSelected)
    const vehicleData = useSelector(state => state.setUserCredit.vehicleData);
    const auth = useSelector((state) => state.auth);

    if (!creditSelected.entity || auth.token === null) {
        history.push('/');
    }

    //DISPATCH
    const dispatch = useDispatch();

    //ACTIONS
    const formValidation = () => dispatch(formValidationAction());
    const successValidation = () => dispatch(validationSuccess());
    const failValidation = () => dispatch(validationFail());
    const simulatorAction = () => dispatch(clearSimulator());
    const setUserCreditAction = () => dispatch(clearUserCredit())

    const formatPrice = (value) => {
        return new Intl.NumberFormat('de-DE').format(parseInt(value))
    }

    const [formdata, setFormdata] = useState({
        brand: vehicleData.brand,
        model: vehicleData.model,
        year: vehicleData.year,
        version: vehicleData.version,
        price: `$ ${formatPrice(vehicleData.price)}`,
        vehicleType: vehicleData.vehicleType,
        capital: `$ ${formatPrice(vehicleData.capital)}`,
        entity: creditSelected.entity,
        line: creditSelected.line,
        lineType: creditSelected.lineType,
        modality: creditSelected.modality,
        amountFee: creditSelected.amountFee,
        firstName: auth.name,
        lastName: auth.lastName,
        phone: auth.phone,
        email: auth.email,
        DNI: '',
        dateOfBirth: '',
        nationality: '',
        netIncome: '',
        jobType: '',
        activity: '',
        timeFrom: '',
        timeTo: '',
        url: vehicleData.url
    })

    const { entity, line, lineType, modality, amountFee, firstName, lastName, phone, email, DNI, dateOfBirth, nationality, netIncome, jobType, activity, timeFrom, timeTo, brand, model, year, version, price, vehicleType, capital } = formdata;

    const readFormData = (e) => {
        setFormdata({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        formValidation();
        if (brand === '' || model === '' || year === '' || version === '' || price === '' || vehicleType === '' || capital === '' || entity === '' || line === '' || lineType === '' || modality === '' || amountFee === '' || firstName === '' || lastName === '' || phone === '' || email === '' || DNI === '' || dateOfBirth === '' || nationality === '' || netIncome === '' || jobType === '' || activity === '') {
            failValidation()
            Swal.fire({
                icon: 'error',
                title: 'Lo siento.',
                text: 'Verificá los campos obligatorios'
            });
            return;
        }
        successValidation();

        formdata.netIncome = `$ ${formatPrice(netIncome)}`;

        const data = formdata;
        const config = {
            headers: { 'Authorization': `Bearer ${auth.token}` }
        }

        clienteAxios.post('/credits-by-users', data, config).then(res => {
            Swal.fire({
                icon: 'success',
                title: 'Correcto!',
                text: 'Tu formulario de preaprobación ha sido enviado correctamente'
            }).then(function () {
                history.push('/')
                simulatorAction();
                setUserCreditAction();
            })
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Lo siento',
                text: 'Error de conexión.'

            })
        })
    }

    //Functions to obtain api results
    const obtainBrandFromAPI = async () => {
        const res = await clienteAxios.get(`/brand/${vehicleData.brand}`);

        return res.data.brand.name;
    }

    const obtainModelFromAPI = async () => {
        const res = await clienteAxios.get(`/model/${vehicleData.model}`);

        return res.data.model.name;
    }

    const obtainVehicleFromAPI = async () => {
        const res = await clienteAxios.get(`/vehicle/${vehicleData.version}`);

        return res.data.vehicle.version_name;
    }

    const obtainVehicleTypeFromAPI = async () => {
        const res = await clienteAxios.get(`/vehicle-type/${vehicleData.vehicleType}`);

        return res.data.vehicleType.name;
    }

    useEffect(() => {
        const fetchIds = async () => {
            const values = await Promise.all([obtainBrandFromAPI(), obtainModelFromAPI(), obtainVehicleFromAPI(), obtainVehicleTypeFromAPI()])
            setFormdata({
                ...formdata,
                brand: values[0],
                model: values[1],
                version: values[2],
                vehicleType: values[3]
            })
        }
        fetchIds()
    }, [])
    return (
        <Layout>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12}>
                        <h1 className="text-center text_red font-weight-bold mt-5">Solicitá</h1>
                    </Col>
                    <Col xs={12}>
                        <Row className="d-flex align-items-center my-5">
                            <Col sm={12} md={6} lg={4}>
                                <h2 className="text-center text_red font-weight-bold">Vehículo</h2>
                            </Col>
                            <Col sm={12} md={6} lg={8}>
                                <Image className="d-none d-md-block" fluid src={lineRed} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={5}>
                        <Form>
                            <Form.Group controlId="brand">
                                <Form.Label className="text_red font-weight-bold">Marca</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="Marca" name="brand" value={brand} disabled />
                            </Form.Group>
                            <Form.Group controlId="model">
                                <Form.Label className="text_red font-weight-bold">Modelo</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="Modelo" name="model" value={model} disabled />
                            </Form.Group>
                            <Form.Group controlId="year">
                                <Form.Label className="text_red font-weight-bold">Año</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="Año" name="year" value={year} disabled />
                            </Form.Group>
                            <Form.Group controlId="version">
                                <Form.Label className="text_red font-weight-bold">Versión</Form.Label>
                                <Form.Control size="lg" type="phone" placeholder="Versión" name="version" value={version} disabled />
                            </Form.Group>
                            <Form.Group controlId="price">
                                <Form.Label className="text_red font-weight-bold">Precio de referencia</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="Precio de referencia" name="price" value={price} disabled />
                            </Form.Group>
                            <Form.Group controlId="vehicleType">
                                <Form.Label className="text_red font-weight-bold">Tipo de Vehículo</Form.Label>
                                <Form.Control size="lg" type="phone" placeholder="Tipo de Vehículo" name="vehicleType" value={vehicleType} disabled />
                            </Form.Group>
                            <Form.Group controlId="capital">
                                <Form.Label className="text_red font-weight-bold">Capital a financiar</Form.Label>
                                <Form.Control size="lg" type="phone" placeholder="Capital a financiar" name="capital" value={capital} disabled />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xs={12}>
                        <Row className="d-flex align-items-center my-5">
                            <Col sm={12} md={6} lg={4}>
                                <h2 className="text-center text_red font-weight-bold">Modalidad de crédito</h2>
                            </Col>
                            <Col sm={12} md={6} lg={8}>
                                <Image className="d-none d-md-block" fluid src={lineRed} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={5}>
                        <Form>
                            <Form.Group controlId="entity">
                                <Form.Label className="text_red font-weight-bold">Entidad</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="Entidad" name="entity" value={entity} disabled />
                            </Form.Group>
                            <Form.Group controlId="line">
                                <Form.Label className="text_red font-weight-bold">Línea</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="Línea" name="Line" value={line} disabled />
                            </Form.Group>
                            <Form.Group controlId="lineType">
                                <Form.Label className="text_red font-weight-bold">Tipo de línea</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="Tipo de línea" name="TypeOfLine" value={lineType} disabled />
                            </Form.Group>
                            <Form.Group controlId="modality">
                                <Form.Label className="text_red font-weight-bold">Modalidad</Form.Label>
                                <Form.Control size="lg" type="phone" placeholder="Modalidad" name="Modality" value={modality} disabled />
                            </Form.Group>
                            <Form.Group controlId="amountOfFees">
                                <Form.Label className="text_red font-weight-bold">Cantidad de cuotas</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="Cantidad de cuotas" name="AmountOfFees" value={amountFee} disabled />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xs={12}>
                        <Row className="d-flex align-items-center my-5">
                            <Col sm={12} md={6} lg={4}>
                                <h2 className="text-center text_red font-weight-bold">Datos para la solicitud</h2>
                            </Col>
                            <Col sm={12} md={6} lg={8}>
                                <Image className="d-none d-md-block" fluid src={lineRed} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={5}>
                        <Form>
                            <Form.Group controlId="name">
                                <Form.Label className="text_red font-weight-bold">Nombre</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="Nombre" name="firstName" value={firstName} onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="lastname">
                                <Form.Label className="text_red font-weight-bold">Apellido</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="Apellido" name="lastName" value={lastName} onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="phone">
                                <Form.Label className="text_red font-weight-bold">Teléfono</Form.Label>
                                <Form.Control size="lg" type="number" placeholder="Teléfono" name="phone" value={phone} onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label className="text_red font-weight-bold">Correo electrónico</Form.Label>
                                <Form.Control size="lg" type="email" placeholder="Correo electrónico" name="email" value={email} onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="dni">
                                <Form.Label className="text_red font-weight-bold">DNI</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="DNI" name="DNI" value={DNI} onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="dateOfBirth">
                                <Form.Label className="text_red font-weight-bold">Fecha de nacimiento</Form.Label>
                                <Form.Control size="lg" type="date" placeholder="Fecha de nacimiento" name="dateOfBirth" value={dateOfBirth} onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="nationality">
                                <Form.Label className="text_red font-weight-bold">Nacionalidad</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="Nacionalidad" name="nationality" value={nationality} onChange={readFormData} />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xs={12}>
                        <Row className="d-flex align-items-center my-5">
                            <Col sm={12} md={6} lg={4}>
                                <h2 className="text-center text_red font-weight-bold">De ingresos</h2>
                            </Col>
                            <Col sm={12} md={6} lg={8}>
                                <Image className="d-none d-md-block" fluid src={lineRed} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={5}>
                        <Form>
                            <Form.Group controlId="netIncome">
                                <Form.Label className="text_red font-weight-bold">Ingresos netos (no bruto) mensuales</Form.Label>
                                <Form.Control size="lg" type="number" placeholder="Ingresos netos mensuales" name="netIncome" value={netIncome} onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="jobType">
                                <Form.Label className="text_red font-weight-bold">Indicar que tipo de trabajo es:</Form.Label>
                                <Form.Control as="select" name="jobType" value={jobType} onChange={readFormData}>
                                    <option value=''>.--.</option>
                                    <option value="Relación de dependencia">Relación de dependencia</option>
                                    <option value="Monotributo">Monotributo</option>
                                    <option value="Autónomo">Autónomo</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="activity">
                                <Form.Label className="text_red font-weight-bold">Actividad</Form.Label>
                                <Form.Control size="lg" type="text" placeholder="Actividad" name="activity" value={activity} onChange={readFormData} />
                            </Form.Group>

                            <Form.Label className="text_red font-weight-bold my-5">Horario disponible para ser contactado <span className='small text-muted'>(Opcional)</span></Form.Label>

                            <Form.Group controlId="from">
                                <Form.Label className="text_red font-weight-bold">Desde</Form.Label>
                                <Form.Control size="lg" type="time" placeholder="" name="timeFrom" value={timeFrom} onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="to">
                                <Form.Label className="text_red font-weight-bold">Hasta</Form.Label>
                                <Form.Control size="lg" type="time" placeholder="" name="timeTo" value={timeTo} onChange={readFormData} />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row className="justify-content-center mb-5 mt-5" >
                    <Col xs={12} sm={12} md={5} >
                        <Button variant="primary" className="w-100" onClick={(e) => formSubmit(e)}>ENVIAR</Button>
                    </Col>
                </Row>

            </Container>
        </Layout>
    );
}

export default Request;