import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Logo from './../../resources/images/logo/tefinancio_logo_white.png';
const Footer = ({ Opp0km, Rmotor }) => {
	return (
		<footer className={`${Opp0km ? "color-portunidades0km" : Rmotor ? "bg_ruiz_dark" : "bg_red"}`}>
			<Container className="py-4">
				<Row className="justify-content-center d-flex align-items-center">
					<Col xs={7} md={6} lg={4} className="text-center order-1">
						<Link to={'/'}><img src={Logo} alt="Logo tefinancio" width="70%" /></Link>
					</Col>
					<Col xs={12} sm={10} md={6} lg={4} className="text-center d-flex align-items-center py-4 py-md-0 pt-md-4 pt-lg-0 order-2 order-md-3 order-lg-2">
						<Col xs={2}>
							<p><i className="fas fa-home"></i></p>
						</Col>
						<Col xs={10}>
							<p className="text-left">
								tefinancio.com.ar es una plataforma digital que pertenece a PLATAFORMAS
								AUTOMOTOR S.A.S.
							</p>
						</Col>
					</Col>
					<Col xs={12} sm={10} md={5} lg={4} className="text-center order-3 order-md-2 order-lg-3">
						<Container>
							<Row className="justify-content-center text-left">
								<Col xs={12} className="d-flex align-items-center">
									<i className="fab fa-instagram pl-2 pl-md-0 pr-4 pr-md-2"></i>
									<a href="https://www.instagram.com/tefinancio.com.ar" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center pl-3 pl-md-0">	@tefinancio.com.ar</a>
								</Col>
								<Col xs={12} className="d-flex align-items-center pt-4">
									<i className="fab fa-facebook-square pl-2 pl-md-0 pr-4 pr-md-2"></i>
									<a href="https://www.facebook.com/tefinancio.com.ar" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center pl-3 pl-md-0"> tefinancio.com.ar</a>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</Container>
			<Container fluid>
				<Row className="border-bottom border-top">
					<Col>
						<p className="text-white text-center py-2">
							© 2020 copyright tefinancio
						</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<p className="text-white text-center py-2">
							Diseñado y desarrollado por <spam><a href="https://aurorastudio.tech/" target="_blank">Aurora Studio</a></spam>
						</p>
					</Col>
				</Row>
			</Container>
		</footer>

	);
}

export default Footer;