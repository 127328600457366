import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '../layout/index';
import LoginStructure from '../structures/LoginStructure';

const Login = ({ history }) => {
    //FUNCTIONS
    const token = useSelector((state) => state.auth.token);
    if (token) {
        history.push('/');
    }

    return (
        <Layout>
            <LoginStructure history={history} />
        </Layout>
    )
}

export default Login
