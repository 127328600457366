import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import Layout from '../layout/index';
//Actions
import { RecoverPasswordAction } from '../../actions/RecoverPasswordAction';
import { formValidationAction, validationSuccess, validationFail } from '../../actions/ValidationAction';

const RecoverPassword = ({ history }) => {
    //READ INPUTS
    const [email, setEmail] = useState('')

    // RENAME DISPATCH
    const dispatch = useDispatch();

    const recoverPassword = (emailData, history) => dispatch(RecoverPasswordAction(emailData, history));
    const formValidation = () => dispatch(formValidationAction());
    const successValidation = () => dispatch(validationSuccess());
    const failValidation = () => dispatch(validationFail());


    const RecoverPasswordHandler = e => {
        e.preventDefault();
        formValidation()
        if (
            email.trim() === ''
        ) {
            failValidation();
            Swal.fire({
                icon: 'error',
                title: 'Lo siento',
                text: 'Campos incompletos'
            })
            return;
        }
        successValidation()
        let data = {
            email: email,
        }
        recoverPassword(data, history);
    }

    const obtainEmailData = e => {
        if (e.target.name === "email") {
            setEmail(e.target.value)
        }
    }
    return (
        <Layout>
            <Container className="py-3">
                <Row className="justify-content-center py-5">
                    <Col xs={12} className="text-center">
                        <h1 className="text_red font-weight-bold">Recuperar contraseña</h1>
                    </Col>
                </Row>
                <Row className="justify-content-center py-3">
                    <Col xs={11} sm={9} md={10} lg={8} xl={5}>
                        <Form>
                            <Form.Group controlId="email">
                                <Form.Control size="lg" type="email" placeholder="Correo electrónico" onChange={(e) => obtainEmailData(e)} name="email" />
                            </Form.Group>
                            <Form.Group>
                                <Button variant="primary" size="lg" type="submit" className="col-12 my-3" onClick={RecoverPasswordHandler}>
                                    RECUPERAR
                                </Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row className="justify-content-center py-3">
                    <Col xs={11} sm={9} md={7} lg={6} xl={5} className="text-center">
                        <p className="text_grey pb-2"><i>¿No tienes cuenta? <Link to={'/registrate'} className="text_red"><u>Regístrate</u></Link></i></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default RecoverPassword
