import { VALIDATION_START, VALIDATION_SUCCESS, VALIDATION_FAIL } from '../actions/ActionTypes';

// state inicial
const initialState = {
    error: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case VALIDATION_START:
            return {
                ...state,
                error: null
            }
        case VALIDATION_SUCCESS:
            return {
                ...state,
                error: null
            }
        case VALIDATION_FAIL:
            return {
                ...state,
                error: true
            }
        default:
            return state
    }
}