import React from 'react';
import { css } from "@emotion/core";
import { HashLoader } from "react-spinners";

const Loading = (props) => {
    // Can be a string as well. Need to ensure each key-value pair ends with ;
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    `;
    return (
        <div className="d-flex align-items-start justify-content-center loading-container">
            <HashLoader
                css={override}
                size={150}
                //size={"150px"} this also works
                color={props.color ? props.color : "#9F111B"}
                loading={props.loading}
            />
        </div>
    );
}

export default Loading;