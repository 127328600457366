import React, { useState, useEffect } from 'react';
import { Nav, Navbar, Image, DropdownButton, NavDropdown, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
// redux
import { useDispatch, useSelector } from 'react-redux';
// logo
import Logo from './../../resources/images/logo/tefinancio_logo_red.png'
import LogoBlue from './../../resources/images/logo/tefinancio_logo_0km.png'
import LogoDark from './../../resources/images/logo/tefinancio_logo_black.png'
import Whatsapp from './../../resources/images/icons/whatsapp_logo.webp'
import { logoutAction, DeleteUserAction } from './../../actions/AuthAction';
import { AddDatabaseAction, DeleteDatabaseAction } from './../../actions/AddDeleteDatabaseAction';

const Header = ({ Opp0km, Rmotor }) => {
	const [isMobile, setIsMobile] = useState(false)
	// FUNCTIONS
	const token = useSelector((state) => state.auth.token);
	const isAdmin = useSelector((state) => state.auth.isAdmin);
	const name = useSelector((state) => state.auth.name);
	const lastName = useSelector((state) => state.auth.lastName);

	const [currentPath, setCurrentPath] = useState("/");

	useEffect(() => {
		setCurrentPath(window.location.pathname)
	}, [currentPath])


	//LOG OUT

	const dispatch = useDispatch();
	const logout = (token) => dispatch(logoutAction(token));
	const deleteUser = (token) => dispatch(DeleteUserAction(token));
	const logoutHandler = () => {
		logout(token)
	}

	const deleteUserHandler = () => {
		Swal.fire({
			title: 'Seguro?',
			text: "¡No podrás revertir esto!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, Borrar!'
		}).then((result) => {
			if (result.value) {
				deleteUser(token)
			}
		})

	}

	// add database
	const addDatabase = (token, platform) => dispatch(AddDatabaseAction(token, platform));
	const deleteDatabase = (token, platform) => dispatch(DeleteDatabaseAction(token, platform));

	const AddDatabaseHandler = (platform) => {
		Swal.fire({
			title: 'Seguro?',
			text: "¡No podrás revertir esto!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, Cargar!'
		}).then((result) => {
			if (result.value) {
				addDatabase(token, platform)
			}
		})

	}
	const DeleteDatabaseHandler = (platform) => {
		Swal.fire({
			title: 'Seguro?',
			text: "¡No podrás revertir esto!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, Borrar!'
		}).then((result) => {
			if (result.value) {
				deleteDatabase(token, platform)
			}
		})
	}

	// detect resolution screens
	useEffect(() => {
		const findScreenWidth = () => {
			let withScreen = window.innerWidth;

			if (withScreen > 768) {
				setIsMobile(false)
			} else {
				setIsMobile(true)
			}
		}

		findScreenWidth()
	}, []);

	return (
		<React.Fragment>
			<Container fluid className="px-0 px-md-5 bg-light">
				<Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="py-4 px-md-5 px-1">
					<Link className="navbar-brand" to="/"><div className="principal-logo"><Image src={Opp0km ? LogoBlue : Rmotor ? LogoDark : Logo} width={280} /></div></Link>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						{
							currentPath !== "/"
								?
								<Nav className={`ml-auto text-center ${Opp0km ? "Opp0km" : Rmotor ? "Rmotor" : "color-ruizautomotores"}`}>
									<Link onClick={() => setCurrentPath(0)} to={'/'} className={`${Opp0km ? "color-portunidades0km" : Rmotor ? "color-ruizautomotores" : "text_red"} font-weight-bold nav-link`}>INICIO</Link>
									<Link onClick={() => window.location.href = window.location.protocol + "//" + window.location.host + "/#simulaTuCredito"} className={`${Opp0km ? "color-portunidades0km" : Rmotor ? "color-ruizautomotores" : "text_red"} font-weight-bold nav-link cursorPointer`}>SIMULA TU CRÉDITO</Link>
									<Link onClick={() => setCurrentPath(1)} to={'/pasos'} className={`${Opp0km ? "color-portunidades0km" : Rmotor ? "color-ruizautomotores" : "text_red"} font-weight-bold nav-link`}>PASOS</Link>
									<Link onClick={() => setCurrentPath(1)} to={'/nosotros'} className={`${Opp0km ? "color-portunidades0km" : Rmotor ? "color-ruizautomotores" : "text_red"} font-weight-bold nav-link`}>NOSOTROS</Link>
									{token
										? <DropdownButton id="dropdown-basic-button" title={`${name + " " + lastName}`}>
											<Link to={'/perfil'} className="dropdown-item dropdown_link">Perfil</Link>
											<Link to={'/modificar-mi-perfil'} className="dropdown-item dropdown_link">Modificar perfil</Link>
											<Link onClick={() => logoutHandler()} className="dropdown-item dropdown_link">Cerrar sesion</Link>
											{
												isAdmin
													?
													<>
														<NavDropdown.Divider />
														<Link onClick={() => DeleteDatabaseHandler('tefinancio')} className="dropdown-item dropdown_link">Borrar tefinancio</Link>
														<Link onClick={() => AddDatabaseHandler('tefinancio')} className="dropdown-item dropdown_link">Cargar tefinancio</Link>
														<NavDropdown.Divider />
														<Link onClick={() => DeleteDatabaseHandler('oportunidades0km')} className="dropdown-item dropdown_link">Borrar oportunidades0km</Link>
														<Link onClick={() => AddDatabaseHandler('oportunidades0km')} className="dropdown-item dropdown_link">Cargar oportunidades0km</Link>
														<NavDropdown.Divider />
														<Link onClick={() => DeleteDatabaseHandler('ruiz automotores')} className="dropdown-item dropdown_link">Borrar ruizautomotores</Link>
														<Link onClick={() => AddDatabaseHandler('ruiz automotores')} className="dropdown-item dropdown_link">Cargar ruizautomotores</Link>
														<NavDropdown.Divider />
														<Link onClick={() => DeleteDatabaseHandler()} className="dropdown-item dropdown_link">Borrar base de datos</Link>
														<Link onClick={() => AddDatabaseHandler()} className="dropdown-item dropdown_link">Cargar base de datos</Link>
													</>
													:
													null
											}
											<NavDropdown.Divider />
											{isAdmin
												? null
												: <Link onClick={() => deleteUserHandler()} className="dropdown-item delete_profile">Eliminar cuenta</Link>
											}
										</DropdownButton>
										: <Link onClick={() => setCurrentPath(1)} to={'/ingresa'} className={`${Opp0km ? "color-portunidades0km" : Rmotor ? "color-ruizautomotores" : "text_red"} font-weight-bold nav-link`}><i className="fas fa-user mr-1"></i>INGRESÁ</Link>
									}

								</Nav>
								:
								<Nav className="ml-auto text-center">
									<Link onClick={() => setCurrentPath(0)} to={'/'} className="text_red font-weight-bold nav-link">INICIO</Link>
									<Nav.Link onClick={() => setCurrentPath(0)} href={"#simulaTuCredito"} className="text_red font-weight-bold nav-link">SIMULA TU CRÉDITO</Nav.Link>
									<Link onClick={() => setCurrentPath(1)} to={'/pasos'} className="text_red font-weight-bold nav-link">PASOS</Link>
									<Link onClick={() => setCurrentPath(1)} to={'/nosotros'} className="text_red font-weight-bold nav-link">NOSOTROS</Link>
									{token
										? <DropdownButton id="dropdown-basic-button" title={`${name + " " + lastName}`}>
											<Link to={'/perfil'} className="dropdown-item dropdown_link">Perfil</Link>
											<Link to={'/modificar-mi-perfil'} className="dropdown-item dropdown_link">Modificar perfil</Link>
											<Link onClick={() => logoutHandler()} className="dropdown-item dropdown_link">Cerrar sesion</Link>
											{
												isAdmin
													?
													<>
														<NavDropdown.Divider />
														<Link onClick={() => DeleteDatabaseHandler('tefinancio')} className="dropdown-item dropdown_link">Borrar tefinancio</Link>
														<Link onClick={() => AddDatabaseHandler('tefinancio')} className="dropdown-item dropdown_link">Cargar tefinancio</Link>
														<NavDropdown.Divider />
														<Link onClick={() => DeleteDatabaseHandler('oportunidades0km')} className="dropdown-item dropdown_link">Borrar oportunidades0km</Link>
														<Link onClick={() => AddDatabaseHandler('oportunidades0km')} className="dropdown-item dropdown_link">Cargar oportunidades0km</Link>
														<NavDropdown.Divider />
														<Link onClick={() => DeleteDatabaseHandler('ruiz automotores')} className="dropdown-item dropdown_link">Borrar ruizautomotores</Link>
														<Link onClick={() => AddDatabaseHandler('ruiz automotores')} className="dropdown-item dropdown_link">Cargar ruizautomotores</Link>
														<NavDropdown.Divider />
														<Link onClick={() => DeleteDatabaseHandler()} className="dropdown-item dropdown_link">Borrar base de datos</Link>
														<Link onClick={() => AddDatabaseHandler()} className="dropdown-item dropdown_link">Cargar base de datos</Link>
													</>
													:
													null
											}
											<NavDropdown.Divider />
											{isAdmin
												? null
												: <Link onClick={() => deleteUserHandler()} className="dropdown-item delete_profile">Eliminar cuenta</Link>
											}
										</DropdownButton>
										: <Link onClick={() => setCurrentPath(1)} to={'/ingresa'} className={`text_red font-weight-bold nav-link`}><i className="fas fa-user mr-1"></i>INGRESÁ</Link>
									}
								</Nav>
						}
					</Navbar.Collapse>
				</Navbar >

			</Container>
			{isMobile ? (
				<a href="whatsapp://send?phone=5493512610627&text=Hola *tefinancio.com.ar*" target='_blank' rel="noopener noreferrer">
					<div className='whatsapp'>
						<Image src={Whatsapp} width={40} />
					</div>
				</a>
			) : (
				<a href="https://web.whatsapp.com/send?phone=5493512610627&text=Hola *tefinancio.com.ar*" target='_blank' rel="noopener noreferrer">
					<div className='whatsapp'>
						<Image src={Whatsapp} width={40} />
					</div>
				</a>
			)}

		</React.Fragment>
	);
}

export default Header;