import { AUTH_START, AUTH_SUCCESS, AUTH_FAIL, LOGOUT, DELETE_USER_START, DELETE_USER_SUCCESS, DELETE_USER_FAIL } from '../actions/ActionTypes';

const initialState = {
    isAdmin: null,
    phone: null,
    DNI: null,
    name: null,
    lastName: null,
    username: null,
    email: null,
    token: null,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AUTH_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case AUTH_SUCCESS:
            return {
                ...state,
                isAdmin: action.payload.data.user.isAdmin,
                phone: action.payload.data.user.phone,
                DNI: action.payload.data.user.DNI,
                name: action.payload.data.user.firstName,
                lastName: action.payload.data.user.lastName,
                username: action.payload.data.user.username,
                email: action.payload.data.user.email,
                token: action.payload.data.token,
                error: null,
                loading: false
            }
        case AUTH_FAIL:
            return {
                ...state,
                isAdmin: null,
                phone: null,
                DNI: null,
                name: null,
                lastName: null,
                username: null,
                email: null,
                token: null,
                error: true,
                loading: false
            }
        case LOGOUT:
            return {
                ...state,
                isAdmin: null,
                phone: null,
                DNI: null,
                name: null,
                lastName: null,
                username: null,
                email: null,
                token: null,
                error: null,
                loading: false,
            }
        case DELETE_USER_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                isAdmin: null,
                phone: null,
                DNI: null,
                name: null,
                LastName: null,
                username: null,
                email: null,
                token: null,
                error: null,
                loading: false,
            }
        case DELETE_USER_FAIL:
            return {
                ...state,
                error: true,
                loading: false
            }
        default:
            return state;
    }
}