import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Image, Button } from "react-bootstrap";
// structures
import FilterSelect from './../structures/FilterSelect';
import QuotaTable from './../structures/QuotaTable';
import StepPurchase from './../structures/StepPurchase';
import StepSell from './../structures/StepSell';
// image
import ImgHeaderMobile from '../../resources/images/page/headerImg.jpg';
//Actions
import { clearSimulator } from '../../actions/SimulatorAction'
import { clearUserCredit } from '../../actions/SetUserCreditAction'
import Layout from '../layout';
import LoginAndSignUpModal from '../structures/LoginAndSignUpModal';
const Home = ({ history }) => {

   const [typeStep, setTypeStep] = useState("comprar");
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const dispatch = useDispatch();

   const simulatorAction = () => dispatch(clearSimulator());
   const setUserCreditAction = () => dispatch(clearUserCredit())

   useEffect(() => {
      simulatorAction();
      setUserCreditAction()
   }, [])

   return (
      <React.Fragment>
         <Layout>
            <header className="md-mb-5 mb-3 banner">
               <Container fluid className="h-100">
                  <Row className="h-100">
                     <Col md={12} lg={6} className="px-0 d-none d-md-block introHeader">
                     </Col>
                     {/* seccion para desktop */}
                     <Col md={12} lg={6} className="px-0 d-none d-md-block bg_red test" >
                        <Container className="h-100">
                           <Row className="justify-content-center  d-flex align-items-center h-100">
                              <Col>
                                 <div className="banner_info text-center my-5">
                                    <h1 className="text-white">tefinancio</h1>
                                    <h4 className="py-4 text-white"><strong>Vendé o comprá</strong> tu vehículo <strong>financiado</strong>, de particular a particular</h4>
                                    <h5 className="text-white">Ponemos a tu alcance, de forma cómoda y simple, las mejores
                                       ofertas de financiación de vehículos usados.</h5>
                                 </div>
                              </Col>
                           </Row>
                        </Container>
                     </Col>
                     {/* seccion para mobil */}
                     <Col xs={12} md={5} className="px-0 d-block d-md-none">
                        <Image src={ImgHeaderMobile} fluid />
                     </Col>
                     <Col xs={12} className="px-0 py-3 d-block d-md-none bg_red text-center" >
                        <h1 className="text-white mb-3">tefinancio</h1>
                        <h3 className="text-white">
                           Vendé o comprá tu vehículo
                           financiado, de particular a particular</h3>
                     </Col>
                  </Row>
               </Container>
            </header>
            {/* ----------components---------- */}
            <FilterSelect history={history} />
            <QuotaTable history={history} handleShow={handleShow} />
            {/* ----------components---------- */}
            <section>
               <Container className="mt-4 mb-5">
                  <Row className="d-flex align-items-center">
                     <Col sm={12} md={8}>
                        <h2 className="text_red font-weight-bold text-center text-md-left mb-4 mb-md-0">Conozca las ventajas y los pasos para comprar o vender <br /> un vehículo financiado</h2>
                     </Col>
                     <Col sm={12} md={4}>
                        <Row className="justify-content-between">
                           <Col xs={6} md={6} className="text-center">
                              {typeStep === "comprar"
                                 ? <Button className="my-2" size="lg" variant="primary">Comprar</Button>
                                 : <Button className="my-2" size="lg" variant="secondary" onClick={() => setTypeStep("comprar")}>Comprar</Button>
                              }
                           </Col>
                           <Col xs={6} md={6} className="text-center">
                              {typeStep === "vender"
                                 ? <Button className="my-2" size="lg" variant="primary">Vender</Button>
                                 : <Button className="my-2" size="lg" variant="secondary" onClick={() => setTypeStep("vender")}>Vender</Button>
                              }

                           </Col>
                        </Row>
                     </Col>
                  </Row>
                  {typeStep === "comprar"
                     ? <StepPurchase />

                     : <StepSell />
                  }

               </Container>
            </section>

         </Layout>
         <LoginAndSignUpModal show={show} onHide={handleClose} history={history} />
      </React.Fragment>
   );
}

export default Home;