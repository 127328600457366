// Validation
export const VALIDATION_START = 'VALIDATION_START';
export const VALIDATION_SUCCESS = 'VALIDATION_SUCCESS';
export const VALIDATION_FAIL = 'VALIDATION_FAIL';

// Sign Up Add User
export const SIGN_UP_START = 'SIGN_UP_START';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';

// Authentication
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const LOGOUT = 'LOGOUT';

//Delete User
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

//Recover Password
export const RECOVER_PASSWORD_START = 'RECOVER_PASSWORD_START';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAIL = 'RECOVER_PASSWORD_FAIL';

//Modify User
export const MODIFY_USER_START = 'MODIFY_USER_START';
export const MODIFY_USER_SUCCESS = 'MODIFY_USER_SUCCESS';
export const MODIFY_USER_FAIL = 'MODIFY_USER_FAIL';

// Admin add remove database
export const ADD_DATABASE_START = "ADD_DATABASE_START";
export const ADD_DATABASE_SUCCESS = "ADD_DATABASE_SUCCESS";
export const ADD_DATABASE_FAIL = "ADD_DATABASE_FAIL";
export const DELETE_STAR = "DELETE_STAR";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAIL = "DELETE_FAIL";

//Simulator
export const SIMULATOR_START = 'SIMULATOR_START';
export const SIMULATOR_SUCCESS = 'SIMULATOR_SUCESS';
export const SIMULATOR_FAIL = 'SIMULATOR_FAIL';
export const CLEAR_SIMULATOR = 'CLEAR_SIMULATOR';

//Credit Selected by User
export const SET_USER_CREDIT_START = 'SET_USER_CREDIT_START';
export const SET_USER_CREDIT_SUCCESS = 'SET_USER_CREDIT_SUCCESS';
export const CLEAR_USER_CREDIT = 'CLEAR_USER_CREDIT';
