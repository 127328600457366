import { SET_USER_CREDIT_START, SET_USER_CREDIT_SUCCESS, CLEAR_USER_CREDIT } from './ActionTypes'

export function SetUserCreditAction(creditSelected, vehicleData) {
    return dispatch => {
        dispatch(setUserCreditStart());
        dispatch(setUserCreditSuccess(creditSelected, vehicleData));
    }
}


export const setUserCreditStart = () => ({
    type: SET_USER_CREDIT_START
})

export const setUserCreditSuccess = (creditSelected, vehicleData) => ({
    type: SET_USER_CREDIT_SUCCESS,
    payload: {
        creditSelected,
        vehicleData
    }
})

export const clearUserCredit = () => ({
    type: CLEAR_USER_CREDIT
})