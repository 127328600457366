import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
// loading
import Loading from "../../loading/Loading"
//ACTIONS
import { SetUserCreditAction } from '../../../actions/SetUserCreditAction';

const QuotaTable = ({ history, Opp0km }) => {
    const [tableValue, setTableValue] = useState("")
    const [tbodycreditdata, setTbodyCreditData] = useState([]);
    const [tbodydescriptiondata, setTbodyDescriptionData] = useState([]);
    const [tbodyquotadata, setTbodyQuotaData] = useState([]);

    //SLECTOR
    const loading = useSelector(state => state.simulator.loading)
    const credits = useSelector((state) => state.simulator.credits);
    const vehicleData = useSelector((state) => state.simulator.vehicleData);
    const creditSelected = useSelector(state => state.setUserCredit.creditSelected);
    const token = useSelector((state) => state.auth.token);
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    // headTableItems
    const theadCredit = ["Entidad", "Línea", "Tipo de línea", "Modalidad", "c/gastos comisión"]
    const theadQuota = [6, 12, 18, 24, 36, 48, 60,]
    const theadDescription = ["test"]

    //DISPATCH
    const dispatch = useDispatch();

    const setUserCreditAction = (creditSelected, vehicleData) => dispatch(SetUserCreditAction(creditSelected, vehicleData));
    const hasAllInputsRequired = vehicleData.brand && vehicleData.model && vehicleData.year && vehicleData.vehicleType && vehicleData.version;

    //FUNCIONS
    const obtainCreditSelected = (value, index, index2) => {
        const creditSelected = {
            entity: credits[index].entity,
            line: tbodycreditdata[index][0],
            lineType: tbodycreditdata[index][1],
            modality: tbodycreditdata[index][2],
            amountFee: theadQuota[index2]
        }

        if (!hasAllInputsRequired) {
            return Swal.fire({
                icon: 'error',
                title: 'Lo siento.',
                text: 'Todos los campos son obligatorios para seleccionar una cuota'
            });
        }

        if (value == 0) {
            const empty = {};
            setUserCreditAction(empty, empty);
        } else {
            setUserCreditAction(creditSelected, vehicleData)
        }
    }

    const formatPrice = (value) => {
        return new Intl.NumberFormat('de-DE').format(parseInt(value))
    }
    //EFFECTS
    useEffect(() => {
        // linea de credito
        const tBodyCreditData = [];
        // observaciones
        const tBodyDescriptionData = []
        // cuotas
        const tBodyQuotaData = []

        setTbodyCreditData(tBodyCreditData);
        setTbodyDescriptionData(tBodyDescriptionData);
        setTbodyQuotaData(tBodyQuotaData);

        if (credits.length === 0) {
            return;
        };

        for (let i = 0; i < credits.length; i++) {
            let element = credits[i];
            tBodyCreditData.push([element.line, element.line_type, element.modality, formatPrice(element.capitalExpensesAndCommission)])
        }

        for (let i = 0; i < credits.length; i++) {
            let element = credits[i];
            tBodyQuotaData.push([
                { item: formatPrice(element.fees.six), id: Math.random() },
                { item: formatPrice(element.fees.twelve), id: Math.random() },
                { item: formatPrice(element.fees.eighteen), id: Math.random() },
                { item: formatPrice(element.fees.twentyfor), id: Math.random() },
                { item: formatPrice(element.fees.thirtysix), id: Math.random() },
                { item: formatPrice(element.fees.fortyegiht), id: Math.random() },
                { item: formatPrice(element.fees.sixty), id: Math.random() },
            ])
        }

        for (let i = 0; i < credits.length; i++) {
            let element = credits[i];
            tBodyDescriptionData.push([element.observation])
        }

        setTbodyCreditData(tBodyCreditData);
        setTbodyDescriptionData(tBodyDescriptionData);
        setTbodyQuotaData(tBodyQuotaData)
    }, [credits]);


    useEffect(() => {
        setTableValue("");
        setUserCreditAction({}, {});
    }, [credits])

    return (
        <>
            {loading ? <Loading color="#132651" />
                : (
                    <Container className="container-modify">
                        <h3 className="mb-4 d-md-none">Revisá nuestras opciones y seleccioná la opción de tu preferencia</h3>
                        <Row className="justify-content-center">
                            <Col md={12} lg={4} className="my-3 pr-lg-0 m-auto">
                                <Table className={`tableResponsive`} >
                                    <thead className="bg_0km">
                                        <tr className="border-0">
                                            <th colSpan="5" className="small px-1 py-2 text-center text-white">Línea de crédito</th>
                                        </tr>
                                    </thead>
                                    <thead className="bg_0km">
                                        <tr>
                                            {theadCredit.map((itemTheadCredit, indexTheadCredit) => {
                                                if (!token && !isAdmin && itemTheadCredit === "c/gastos comisión") return
                                                return (
                                                    <th className="small px-1 py-2 text-center text-white" key={indexTheadCredit}>{itemTheadCredit}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tbodycreditdata.length !== 0 ? tbodycreditdata.map((itemTBodyCredit, indexTBodyCredit) => (
                                            <tr key={indexTBodyCredit}>
                                                <td className="small px-0 py-2 px-2" style={{ height: 70 }}>
                                                    <div className="h-100 w-100 d-flex align-items-center pl-1">
                                                        <p className="firstItemTable text-left">{credits[indexTBodyCredit]?.entity}</p>
                                                    </div>
                                                </td>
                                                {itemTBodyCredit.map((itemTBodyCredit2, indexTBodyCredit2) => {
                                                    if (!token && !isAdmin && itemTBodyCredit.length - 1 === indexTBodyCredit2) return
                                                    return (
                                                        <td className="small px-0 py-2 text-center px-2" style={{ height: 70 }} key={indexTBodyCredit2}>
                                                            <div className="h-100 w-100 d-flex align-items-center">
                                                                <p className='text-left'>{itemTBodyCredit2}</p>
                                                            </div>
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td className="small px-0 py-2 px-2" style={{ height: 70 }} colSpan={5}></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={12} lg={5} className="my-3 px-lg-0">
                                <Table className={`tableResponsive`} >
                                    <thead className="bg_0km">
                                        <tr className="border-0">
                                            <th colSpan="8" className="small px-1 py-2 text-center text-white">Cantidad de cuotas</th>
                                        </tr>
                                    </thead>
                                    <thead className="bg_0km">
                                        <tr>
                                            <th className="small px-1 py-2 text-center text-white d-block d-lg-none"> </th>
                                            {theadQuota.map((item, index) => (
                                                <th className="small px-1 py-2 text-center text-white" key={index}>{item}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tbodyquotadata.length !== 0 ? tbodyquotadata.map((item, index) => (
                                            <tr key={index}>
                                                <td className="small px-0 py-2 px-2 d-block d-lg-none border-0 cell-responsive-table" style={{ height: 70 }}>
                                                    <div className="h-100 w-100 d-flex align-items-center">
                                                        <p className={`${Opp0km ? "color-portunidades0km" : "firstItemTable"}`}>{credits[index]?.entity}</p>
                                                    </div>
                                                </td>
                                                {item.map((item2, index2) => (
                                                    <td onClick={(e) => {
                                                        setTableValue(item2.id)
                                                        obtainCreditSelected(item2.item, index, index2)

                                                    }} className={`small py-2 cursorPointer ${item2.id === tableValue ? !hasAllInputsRequired || item2.item == 0 ? null : "bg_grey" : null}`} style={{ height: 70 }} key={index2}>
                                                        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                                                            <p className={item2.id === tableValue ? !hasAllInputsRequired || item2.item == 0 ? null : "text_red" : null}>{item2.item}</p>
                                                        </div>
                                                    </td>
                                                ))}
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td className="small px-0 py-2 px-2" style={{ height: 70 }} colSpan={8}>
                                                    <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                                                        <p>No hay créditos</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={12} lg={3} className="my-3 pl-lg-0">
                                <Table className={`tableResponsive`} >
                                    <thead className="bg_0km">
                                        <tr className="border-0">
                                            <th colSpan="2" className="small px-1 py-2 text-center text-white">Observaciones</th>
                                        </tr>
                                    </thead>
                                    <thead className="bg_0km">
                                        <tr>
                                            {theadDescription.map((item, index) => (
                                                <th colSpan="2" className="small px-1 py-2 text-center text-white color-portunidades0km" key={index}>{item}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tbodydescriptiondata.length !== 0 ? tbodydescriptiondata.map((item, index) => (
                                            <tr key={index}>
                                                <td className="small px-0 py-2 px-2 d-block d-lg-none border-0 cell-responsive-table" style={{ height: 70 }}>
                                                    <div className="h-100 d-flex align-items-center pl-1">
                                                        <p className={`${Opp0km ? "color-portunidades0km" : "firstItemTable"}`}>{credits[index]?.entity}</p>
                                                    </div>
                                                </td>
                                                {item.map((item2, index2) => (
                                                    <td className="small px-0 py-2 px-2" style={{ height: 70 }} key={index2}>
                                                        <div className="h-100 d-flex align-items-center pl-1">
                                                            <p>{item2}</p>
                                                        </div>
                                                    </td>
                                                ))}
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td className="small px-0 py-2 px-2" style={{ height: 70 }} colSpan={2}></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={12} className="mb-5">
                                <p className='text-muted'>*En la cuota y el capital podrían estar  incluidos gastos y honorarios, y a la cuota promedio  podría sumarse el valor del seguro automotor.</p>
                            </Col>
                        </Row>
                    </Container>
                )}
        </>
    );
}

export default QuotaTable;
