import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers/index';

const persistConfig = {
    key: 'auth',
    storage: storage,
    whitelist: ['auth'] // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(thunk, logger);
// const middleware = compose(applyMiddleware(thunk, logger),
//     window.__REDUX_DEVTOOLS_EXTENSION__ &&
//     window.__REDUX_DEVTOOLS_EXTENSION__())
const store = createStore(pReducer, middleware);

const persistor = persistStore(store);

export { persistor, store };


// const initialState = {};
// const middleware = [thunk];
// const store = createStore(
//  rootReducer,
//  initialState,
//  compose(applyMiddleware(...middleware, thunk, logger),
//  window.__REDUX_DEVTOOLS_EXTENSION__ &&
//  window.__REDUX_DEVTOOLS_EXTENSION__()
 // https://github.com/zalmoxisus/redux-devtools-extension
 // compose(applyMiddleware(...middleware)
//  ));
// export default store;
