import { SIGN_UP_START, SIGN_UP_SUCCESS, SIGN_UP_FAIL, AUTH_SUCCESS } from './ActionTypes';
import clienteAxios from '../config/Axios'
import Swal from 'sweetalert2';

export function SignUpAction(data, history, modal) {

    return dispatch => {
        dispatch(signUpStart())
        clienteAxios.post('/users', data)
            .then((userData) => {
                dispatch(signUpSuccess())
                dispatch(authSuccess(userData))
                Swal.fire({
                    icon: 'success',
                    title: "¡El usuario ha sido creado correctamente!",
                    // text: "Inicie sesión para validar su información",
                }).then(function () {
                    // history.push('/iniciar-sesion');
                    if (!modal) {
                        history.push('/');
                    } else {
                        modal();
                        history.push('/solicita');
                    }
                });
            })
            .catch(error => {
                dispatch(signUpFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'No autorizado.'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error'
                    })
                }
            })
    }
}

export const signUpStart = () => {
    return {
        type: SIGN_UP_START
    };
};

export const signUpSuccess = () => ({
    type: SIGN_UP_SUCCESS
})

export const signUpFail = () => ({
    type: SIGN_UP_FAIL
})

export const authSuccess = (user) => ({
    type: AUTH_SUCCESS,
    payload: user
})


